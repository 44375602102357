import { useEffect, useState } from "react";
import AddEditModalDX from "../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../controls/textfielddx";
import GridDX from "../layout/griddx";
import Loading from "../loading";
import { useNotificationContext } from "../../context/notificationcontext";
import useDepartmentService from "../../shared/services/departmentservice";

const DepartmentModal = (props: any) => {
  const { open, handleClose, departmentCode } = props;
  const { t } = useTranslation();
  const { setError, setInfo } = useNotificationContext();
  const { addDepartment, updateDepartment, getDepartmentByDepCode } =
    useDepartmentService();
  const defaultValues = {
    departmentCode: Math.floor(Math.random() * 1000000000).toString(),
    name: "",
    integrationCode: Math.floor(Math.random() * 1000000000).toString(),
  };

  const [departmentData, setDepartmentData] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (departmentCode) {
      setIsLoading(true);
      getDepartmentByDepCode(departmentCode)
        .then((res) => setDepartmentData(res))
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    // if (departmentData.enName.length === 0)
    //   newErrors["enName"] = t("English name is required");
    // if (departmentData.arName.length === 0)
    // newErrors["arName"] = t("Arabic name is required");

    if (departmentData.name.length === 0)
      newErrors["name"] = t("Name is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      setIsLoading(true);
      departmentCode
        ? updateDepartment(departmentData)
            .then(() => {
              setInfo(t("Department updated successfully"));
              handleClose(true);
            })
            .catch((err) => setError(err))
            .finally(() => setIsLoading(false))
        : addDepartment(departmentData)
            .then(() => {
              setInfo(t("Department created successfully"));
              handleClose(true);
            })
            .catch((err) => setError(err))
            .finally(() => setIsLoading(false));
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setDepartmentData({
      ...departmentData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={departmentCode ? t("Edit Department") : t("Create Department")}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Name")}
            name="name"
            value={departmentData.name}
            onChange={handleInputChange}
            error={errors["name"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Department Code")}
            name="departmentCode"
            value={departmentData.departmentCode}
            onChange={handleInputChange}
            error={errors["departmentCode"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Integration Code")}
            name="integrationCode"
            value={departmentData.integrationCode}
            onChange={handleInputChange}
            error={errors["integrationCode"]}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default DepartmentModal;
