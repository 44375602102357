import React from "react";
import GridDX from "../../layout/griddx";
import TextFieldDX from "../../controls/textfielddx";
import { useTranslation } from "react-i18next";
import UploadBoxDX from "../../controls/uploadboxdx";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";
import SelectListDX from "../../controls/selectlistdx";
import ButtonDX from "../../controls/buttondx";
import useAssetPictureService from "../../../shared/services/assetpictureservice";
import { useNotificationContext } from "../../../context/notificationcontext";
const PictureTab = (props: any) => {
  const { assetData, setAssetData } = props;
  const { setError, setInfo } = useNotificationContext();
  const { addAssetPicture, deleteAssetPicture } = useAssetPictureService();
  const handleImageChange = (image: any, type: string) => {
    if (type === "external" && externalUrl == "") {
      return;
    }

    console.log(image);

    setExternalUrl("");

    if (assetData.assetId != 0) {
      const formData = new FormData();
      formData.append("remoteUrl", type === "external" ? image : "");
      formData.append(
        "assetId",
        assetData.assetId !== 0 ? assetData.assetId : "0"
      );
      formData.append("assetPictureId", "0");
      formData.append("uploadLocation", type === "local" ? image : "");

      addAssetPicture(formData)
        .then((res) => {
          setInfo("Picture added successfully");
          const newPic = {
            remoteUrl: type === "external" ? image : "",
            assetId: assetData.assetId !== 0 ? assetData.assetId : 0,
            assetPictureId: 0,
            uploadLocation: type === "local" ? image : "",
          };
          const newAssetPictures = assetData.assetPictures;
          newAssetPictures.push(newPic);
          setAssetData({
            ...assetData,
            assetPictures: newAssetPictures,
          });
        })
        .catch((err) => {
          setError("Error adding picture");
          const newAssetPictures = assetData.assetPictures;
          newAssetPictures.pop();
          setAssetData({
            ...assetData,
            assetPictures: newAssetPictures,
          });
          console.log(err);
        });
    }
  };

  const handleDelete = (index: number) => {
    deleteAssetPicture(assetData.assetPictures[index].assetPictureId)
      .then((res) => {
        const newAssetPictures = assetData.assetPictures.filter(
          (item: any, i: number) => i !== index
        );
        setAssetData({
          ...assetData,
          assetPictures: newAssetPictures,
        });
        setInfo("Picture deleted successfully");
      })
      .catch((err) => {
        setError("Error deleting picture");
        console.log(err);
      });
  };

  const { t } = useTranslation();
  const [imageType, setImageType] = React.useState("");
  const [externalUrl, setExternalUrl] = React.useState("");
  const imageTypes = [
    { value: "local", text: "Upload" },
    { value: "external", text: "External Link" },
  ];
  return (
    <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
      <GridDX item xs={6}>
        <SelectListDX
          items={imageTypes}
          name="imageType"
          label={t("Image type")}
          onChange={(e: any) => setImageType(e.target.value)}
        />
      </GridDX>
      {imageType === "external" && (
        <GridDX item xs={6} rowSpacing={2} sx={{ alignItems: "center" }}>
          <TextFieldDX
            label={t("Image URL")}
            name="imageUrl"
            value={externalUrl}
            onChange={(e: any) => setExternalUrl(e.target.value)}
          />
          <ButtonDX
            onClick={() => handleImageChange(externalUrl, "external")}
            sx={{ marginLeft: "20px", width: "150px", height: "40px" }}
          >
            Add
          </ButtonDX>
        </GridDX>
      )}
      {imageType === "local" && (
        <GridDX item xs={6} justifyContent="center">
          <UploadBoxDX
            closeIcon={false}
            label={t("Picture")}
            setImage={(image: any) => handleImageChange(image, "local")}
            getFileImage={true}
          />
        </GridDX>
      )}

      <GridDX item xs={12} sx={{ marginTop: "20px" }}>
        <GridDX container columnSpacing={1} rowSpacing={2}>
          {assetData?.assetPictures?.map((item: any, index: number) =>
            item.remoteUrl !== "" && item.remoteUrl !== null ? (
              <GridDX
                item
                xs={2}
                key={index}
                sx={{ position: "relative", overFlow: "hidden" }}
              >
                <img
                  src={item.remoteUrl}
                  alt="asset"
                  style={{
                    objectFit: "cover",
                    height: "150px",
                    width: "150px",
                  }}
                />
                <IconButton
                  sx={{ position: "absolute", right: "0px" }}
                  onClick={() => handleDelete(index)}
                >
                  <CloseIcon />
                </IconButton>
              </GridDX>
            ) : (
              <GridDX item xs={2} key={index} sx={{ position: "relative" }}>
                <img
                  src={
                    item?.uploadLocation &&
                    URL.createObjectURL(item?.uploadLocation)
                  }
                  alt="asset"
                  style={{
                    objectFit: "cover",
                    height: "150px",
                    width: "150px",
                  }}
                />
                <IconButton
                  sx={{ position: "absolute", right: "0px" }}
                  onClick={() => handleDelete(index)}
                >
                  <CloseIcon />
                </IconButton>
              </GridDX>
            )
          )}
        </GridDX>
      </GridDX>
    </GridDX>
  );
};

export default PictureTab;
