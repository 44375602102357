import useSecureAPI from "../hooks/usesecureapi";

const useReportService = () => {
  const secureAPI = useSecureAPI();

  const generateReport = async (data: any) => {
    const response = await secureAPI.post("Report/assetreport", data);
    return response.data;
  };

  return {
    generateReport,
  };
};

export default useReportService;
