import { useEffect, useState } from "react";
import { useNotificationContext } from "../../context/notificationcontext";
import TreeViewDX from "../../components/treeviewDX";
import { useTranslation } from "react-i18next";
import useCategoryService from "../../shared/services/categoryservice";
import AddEditCategoryModal from "../../components/addeditmodals/addEditCategoryModal";
import Loading from "../../components/loading";

const CategoryPage = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setInfo, setError } = useNotificationContext();
  const { getCategories, deleteCategory } = useCategoryService();
  const [data, setData] = useState<any>([]);
  const [parentNode, setParentNode] = useState(null);
  const [editNode, setEditNode] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    getData();
  }, []);

  const onDelete = (id: number) => {
    deleteCategory(id)
      .then((res) => {
        setInfo("Deleted Successfully");
        getData();
      })
      .catch((err) => {
        setError("Error Deleting", err);
        console.log(err, "err");
      });
  };
  const onEdit = (node: any) => {
    setEditNode(node);
    setOpen(!open);
  };
  const toCreate = (parentNode: any) => {
    setParentNode(parentNode);
    setOpen(!open);
  };
  const onClose = (refreshPage: boolean = false) => {
    setEditNode(null);
    setParentNode(null);
    setOpen(!open);
    if (refreshPage) getData();
  };

  const getData = () => {
    setLoading(true);

    getCategories()
      .then((allCategories) => {
        const parentRes = allCategories.filter(
          (item: { parentCategoryId: null }) => item.parentCategoryId === null
        );
        const allRootCategoriesData = parentRes.map((parent: any) =>
          getCategoryByParent(parent, allCategories)
        );

        setData(allRootCategoriesData.flat());
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCategoryByParent = (parent: any, allCategories: any) => {
    const childrenRes = allCategories.filter(
      (item: { parentCategoryId: any }) =>
        item.parentCategoryId === parent.categoryId ||
        item.parentCategoryId === parent.id
    );

    const updatedRes = childrenRes.map(
      (item: { [x: string]: any; categoryId: any }) => {
        const { categoryId, ...rest } = item;
        return { id: categoryId, ...rest };
      }
    );

    return {
      id: parent.categoryId,
      ...parent,
      children: updatedRes.map((item: any) =>
        getCategoryByParent(item, allCategories)
      ),
    };
  };

  if (loading) return <Loading />;
  else
    return (
      <>
        {open && (
          <AddEditCategoryModal
            open={open}
            handleClose={onClose}
            parentNode={parentNode}
            editNode={editNode}
          />
        )}

        <TreeViewDX
          data={data}
          toCreate={toCreate}
          onEdit={onEdit}
          onDelete={onDelete}
          name={t("category")}
        />
      </>
    );
};

export default CategoryPage;
