import useSecureAPI from "../hooks/usesecureapi";

const useLocationService = () => {
  const secureAPI = useSecureAPI();

  const getLocation = async () => {
    const response = await secureAPI.get(`Location`);
    return response.data;
  };
  const getLocationByID = async (id: number) => {
    const response = await secureAPI.get(`Location/${id}`);
    return response.data;
  };
  const getLocationByParentID = async (id: number | null) => {
    const response = await secureAPI.get(`Location/byparent/${id}`);
    return response.data;
  };

  const addLocation = async (data: any) => {
    const response = await secureAPI.post(`Location`, data);
    return response.data;
  };

  const updateLocation = async (data: any) => {
    const response = await secureAPI.put(`Location`, data);
    return response.data;
  };

  const deleteLocation = async (id: number) => {
    const response = await secureAPI.delete(`Location/${id}`);
    return response.data;
  };

  // const getUnreadNotificationsCount = async (id: any) => {
  //   const response = await secureAPI.get(`UserNotification/unreadcount/${id}`);
  //   return response.data;
  // };

  // const markAsRead = async (id: any) => {
  //   const response = await secureAPI.put(`UserNotification/MarkRead/${id}`);
  //   return response.data;
  // };

  // const markUnRead = async (id: any) => {
  //   const response = await secureAPI.put(`UserNotification/MarkUnRead/${id}`);
  //   return response.data;
  // };

  // const markAsArchived = async (id: any) => {
  //   const response = await secureAPI.delete(`UserNotification/${id}`);
  //   return response.data;
  // };

  // const markAllUnRead = async (userId: any) => {
  //   const response = await secureAPI.put(
  //     `UserNotification/MarkAllUnRead/foruser/${userId}`
  //   );
  //   return response.data;
  // };

  return {
    getLocationByID,
    getLocationByParentID,
    addLocation,
    deleteLocation,
    updateLocation,
    getLocation
  };
};

export default useLocationService;
