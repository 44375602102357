import React, { useEffect } from "react";
import GridDX from "../../layout/griddx";
import TextFieldDX from "../../controls/textfielddx";
import { useTranslation } from "react-i18next";
import DatePickerDX from "../../controls/datepickerdx";
import SelectListDX from "../../controls/selectlistdx";
import CheckBoxDX from "../../controls/checkboxdx";
import UploadBoxDX from "../../controls/uploadboxdx";

const CategoryTab = (props: any) => {
  const { assetData, setAssetData, errors, categories, handleInputChange } =
    props;
  const { t } = useTranslation();

  const [l1Categories, setL1Categories] = React.useState<any>([]);
  const [l2Categories, setL2Categories] = React.useState<any>([]);
  const [l3Categories, setL3Categories] = React.useState<any>([]);
  const [l4Categories, setL4Categories] = React.useState<any>([]);
  const [l5Categories, setL5Categories] = React.useState<any>([]);
  const [l6Categories, setL6Categories] = React.useState<any>([]);
  const [l7Categories, setL7Categories] = React.useState<any>([]);

  useEffect(() => {
    let l1 = categories.filter((l: any) => l.parentCategoryId === null);
    l1 = l1.map((l: any) => ({
      value: l.categoryId,
      text: l.name,
    }));
    setL1Categories(l1);
  }, []);
  useEffect(() => {
    let l2 = categories.filter(
      (l: any) =>
        l.parentCategoryId === assetData.l1CategoryId && l.levelNumber === 2
    );
    l2 = l2.map((l: any) => ({
      value: l.categoryId,
      text: l.name,
    }));
    setL2Categories(l2);
  }, [assetData.l1CategoryId]);

  useEffect(() => {
    let l3 = categories.filter(
      (l: any) =>
        l.parentCategoryId === assetData.l2CategoryId && l.levelNumber === 3
    );
    l3 = l3.map((l: any) => ({
      value: l.categoryId,
      text: l.name,
    }));
    setL3Categories(l3);
  }, [assetData.l2CategoryId]);

  useEffect(() => {
    let l4 = categories.filter(
      (l: any) =>
        l.parentCategoryId === assetData.l3CategoryId && l.levelNumber === 4
    );
    l4 = l4.map((l: any) => ({
      value: l.categoryId,
      text: l.name,
    }));
    setL4Categories(l4);
  }, [assetData.l3CategoryId]);

  const handleCategoryChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    if (name === "l1CategoryId") {
      setAssetData({
        ...assetData,
        [name]: value,
        l2CategoryId: null,
        l3CategoryId: null,
        l4CategoryId: null,
        l5CategoryId: null,
        l6CategoryId: null,
        l7CategoryId: null,
      });
    } else if (name === "l2CategoryId") {
      setAssetData({
        ...assetData,
        [name]: value,
        l3CategoryId: null,
        l4CategoryId: null,
        l5CategoryId: null,
        l6CategoryId: null,
        l7CategoryId: null,
      });
    } else if (name === "l3CategoryId") {
      setAssetData({
        ...assetData,
        [name]: value,
        l4CategoryId: null,
        l5CategoryId: null,
        l6CategoryId: null,
        l7CategoryId: null,
      });
    } else if (name === "l4CategoryId") {
      setAssetData({
        ...assetData,
        [name]: value,
        l5CategoryId: null,
        l6CategoryId: null,
        l7CategoryId: null,
      });
    } else if (name === "l5CategoryId") {
      setAssetData({
        ...assetData,
        [name]: value,
        l6CategoryId: null,
        l7CategoryId: null,
      });
    } else if (name === "l6CategoryId") {
      setAssetData({
        ...assetData,
        [name]: value,
        l7CategoryId: null,
      });
    } else {
      setAssetData({
        ...assetData,
        [name]: value,
      });
    }
  };

  return (
    <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
      <GridDX item xs={12}>
        <SelectListDX
          items={l1Categories}
          name="l1CategoryId"
          label={t("Category")}
          onChange={handleCategoryChange}
          value={assetData.l1CategoryId}
          error={errors["l1CategoryId"]}
          InputLabelProps={{
            shrink: assetData.l1CategoryId !== null, // Set to true if there's a value just to handle label position
          }}
        />
      </GridDX>

     

      <GridDX item xs={12}>
        <SelectListDX
          items={l2Categories}
          name="l2CategoryId"
          label={t("Sub Category")}
          onChange={handleCategoryChange}
          value={assetData.l2CategoryId}
          error={errors["l2CategoryId"]}
          InputLabelProps={{
            shrink: assetData.l2CategoryId !== null, // Set to true if there's a value just to handle label position
          }}
        />
      </GridDX>

      

      <GridDX item xs={12}>
        <SelectListDX
          items={l3Categories}
          name="l3CategoryId"
          label={t("Group")}
          onChange={handleCategoryChange}
          value={assetData.l3CategoryId}
          error={errors["l3CategoryId"]}
          InputLabelProps={{
            shrink: assetData.l3CategoryId !== null, // Set to true if there's a value just to handle label position
          }}
        />
      </GridDX>
    </GridDX>
  );
};

export default CategoryTab;
