import useSecureAPI from "../hooks/usesecureapi";

const useCategoryService = () => {
  const secureAPI = useSecureAPI();
  const getCategories = async () => {
    const response = await secureAPI.get(`Category`);
    return response.data;
  };

  const getCategoryByID = async (id: number) => {
    const response = await secureAPI.get(`Category/${id}`);
    return response.data;
  };
  const getCategoryByParentID = async (id: number) => {
    const response = await secureAPI.get(`Category/byparent/${id}`);
    return response.data;
  };

  const addCategory = async (data: any) => {
    const response = await secureAPI.post(`Category`, data);
    return response.data;
  };

  const updateCategory = async (data: any) => {
    const response = await secureAPI.put(`Category`, data);
    return response.data;
  };

  const deleteCategory = async (id: number) => {
    const response = await secureAPI.delete(`Category/${id}`);
    return response.data;
  };

  return {
    getCategories,
    getCategoryByID,
    getCategoryByParentID,
    addCategory,
    updateCategory,
    deleteCategory,
  };
};

export default useCategoryService;
