import useSecureAPI from "../hooks/usesecureapi";

const useAssetService = () => {
  const secureAPI = useSecureAPI();

  const getAssets = async () => {
    const response = await secureAPI.get(`Asset`);
    return response.data;
  };
  const getAssetById = async (id: number) => {
    const response = await secureAPI.get(`Asset/${id}`);
    return response.data;
  };

  const addAsset = async (data: any) => {
    const response = await secureAPI.post(`Asset`, data);
    return response.data;
  };

  const updateAsset = async (data: any) => {
    const response = await secureAPI.put(`Asset`, data);
    return response.data;
  };

  const deleteAsset = async (id: number) => {
    const response = await secureAPI.delete(`Asset/${id}`);
    return response.data;
  };

  const getAssetTypes = async () => { 
    const response = await secureAPI.get(`AssetType`);
    return response.data;
  }
  const getAssetTypeById = async (id: number) => {
    const response = await secureAPI.get(`AssetType/${id}`);
    return response.data;
  }
  const getAssetConditionTypes = async () => {
    const response = await secureAPI.get(`AssetConditionType`);
    return response.data;
  }
  const getAssetConditionTypeById = async (id: number) => {
    const response = await secureAPI.get(`AssetConditionType/${id}`);
    return response.data;
  }

  const getAssetStatusTypes = async () => {
    const response = await secureAPI.get(`AssetStatusType`);
    return response.data;
  }
  const getAssetStatusTypeById = async (id: number) => {
    const response = await secureAPI.get(`AssetStatusType/${id}`);
    return response.data;
  }

  return {
    addAsset,
    deleteAsset,
    updateAsset,
    getAssets,
    getAssetById,
    getAssetTypes,
    getAssetConditionTypes,
    getAssetStatusTypes,
    getAssetTypeById,
    getAssetConditionTypeById,
    getAssetStatusTypeById
  };
};

export default useAssetService;
