import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GridDX from "../../layout/griddx";
import Loading from "../../loading";
import { useNotificationContext } from "../../../context/notificationcontext";
import useDepartmentService from "../../../shared/services/departmentservice";
import BoxDX from "../../layout/boxdx";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import DetailsTab from "./detailstab";
import ManufacturerTab from "./manufacturertab";
import useUserService from "../../../shared/services/userservices";
import useCategoryService from "../../../shared/services/categoryservice";
import useLocationService from "../../../shared/services/locationservice";
import useAssetService from "../../../shared/services/assetservice";
import useCustodyService from "../../../shared/services/custodyservice";
import useEmployeeService from "../../../shared/services/employeeservice";
import LocationTab from "./locationtab";
import CategoryTab from "./categorytab";
import PictureTab from "./picturetab";
import usePendingAssetService from "../../../shared/services/pendingassetservice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ConfirmModal from "../../controls/confirmmodal";
import ButtonDX from "../../controls/buttondx";
import LoadingButtonDX from "../../controls/loadingbuttondx";
import PendingAssetConfirmModal from "../../controls/pendingassetconfirmmodal";

const PendingAssetTabsModal = (props: any) => {
  const { handleClose, pendingAssetId } = props;
  const { t } = useTranslation();
  const { setError, setInfo } = useNotificationContext();
  const {
    getAssetById,
    addAsset,
    updateAsset,
    getAssetTypes,
    getAssetConditionTypes,
    getAssetStatusTypes,
  } = useAssetService();
  const { getPendingAssetById, updatePendingAsset, approvePendingAsset } =
    usePendingAssetService();
  const { getDepartments } = useDepartmentService();
  const { getActiveUsers } = useUserService();
  const { getCategories } = useCategoryService();
  const { getLocation } = useLocationService();
  const { getCustodyTypes } = useCustodyService();
  const { getEmployees } = useEmployeeService();

  const defaultValues = {
    pendingAssetId: 0,
    assetCode: Math.floor(Math.random() * 1000000000).toString(),
    shortDescription: "",
    entryDate: new Date(),
    enteredByUserId: null,
    l1CategoryId: null,
    l2CategoryId: null,
    l3CategoryId: null,
    l4CategoryId: null,
    l5CategoryId: null,
    l6CategoryId: null,
    l7CategoryId: null,
    l1LocationId: null,
    l2LocationId: null,
    l3LocationId: null,
    l4LocationId: null,
    l5LocationId: null,
    l6LocationId: null,
    l7LocationId: null,
    assetConditionTypeId: 0,
    assetTypeId: 0,
    assetStatusTypeId: 0,
    isTangible: true,
    isActive: true,
    custodianEmployeeId: 0,
    custodyTypeId: 0,
    barCode: "",
    departmentCode: null,
    oldTag: "",
    manufacturerCode: "",
    manufacturerName: "",
    manufacturerSerialCode: "",
    manufacturerBrand: "",
    manufacturerModel: "",
    manufacturerSpecification: "",
    manufacturerSize: "",
    assetPictures: [],
  };

  const [assetData, setAssetData] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const [errors, setErrors] = useState<any>({});

  const [usersDropDownData, setUsersDropDownData] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [locations, setLocations] = useState<any>([]);
  const [departmentsDropDownData, setDepartmentsDropDownData] = useState<any>(
    []
  );
  const [assetTypesDropDownData, setAssetTypesDropDownData] = useState<any>([]);
  const [assetConditionTypesDropDownData, setAssetConditionTypesDropDownData] =
    useState<any>([]);
  const [custodyTypesDropDownData, setCustodyTypesDropDownData] = useState<any>(
    []
  );
  const [assetStatusTypesDropDownData, setAssetStatusTypesDropDownData] =
    useState<any>([]);
  const [custodianDropDownData, setCustodianDropDownData] = useState<any>([]);

  useEffect(() => {
    setIsLoading(true);
    const p1 = pendingAssetId ? getPendingAssetById(pendingAssetId) : null;
    const p2 = getActiveUsers();
    const p3 = getCategories();
    const p4 = getLocation();
    const p5 = getDepartments();
    const p6 = getAssetTypes();
    const p7 = getAssetConditionTypes();
    const p8 = getCustodyTypes();
    const p9 = getAssetStatusTypes();
    const p10 = getEmployees();
    Promise.all([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10])
      .then(
        ([
          asset,
          users,
          categories,
          locations,
          departments,
          assetTypes,
          assetConditionTypes,
          custodyTypes,
          assetStatusTypes,
          employees,
        ]) => {
          if (pendingAssetId) {
            setAssetData({
              ...asset,
              entryDate: new Date(asset.entryDate),
            });
          }
          const dataForUsersDropDown = users.map((res: any) => ({
            text: res.fullName,
            value: res.id,
          }));
          setUsersDropDownData(dataForUsersDropDown);

          setCategories(categories);

          setLocations(locations);

          const dataForDepartmentsDropDown = departments.map((res: any) => ({
            text: res.name,
            value: res.departmentCode,
          }));
          setDepartmentsDropDownData(dataForDepartmentsDropDown);

          const dataForAssetTypesDropDown = assetTypes.map((res: any) => ({
            text: res.description,
            value: res.assetTypeId,
          }));
          setAssetTypesDropDownData(dataForAssetTypesDropDown);

          const dataForAssetConditionTypesDropDown = assetConditionTypes.map(
            (res: any) => ({
              text: res.description,
              value: res.assetConditionTypeId,
            })
          );
          setAssetConditionTypesDropDownData(
            dataForAssetConditionTypesDropDown
          );

          const dataForCustodyTypesDropDown = custodyTypes.map((res: any) => ({
            text: res.description,
            value: res.custodyTypeId,
          }));
          setCustodyTypesDropDownData(dataForCustodyTypesDropDown);
          const dataForAssetStatusTypesDropDown = assetStatusTypes.map(
            (res: any) => ({
              text: res.description,
              value: res.assetStatusTypeId,
            })
          );
          setAssetStatusTypesDropDownData(dataForAssetStatusTypesDropDown);
          const dataForCustodianDropDown = employees.map((res: any) => ({
            text: res.enFullname,
            value: res.employeeId,
          }));
          setCustodianDropDownData(dataForCustodianDropDown);
        }
      )
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (assetData?.custodyTypeId === null) {
      newErrors["custodyTypeId"] = t("Custody Type is required");
      setTab("1");
    }

    if (
      assetData.shortDescription == null ||
      assetData.shortDescription.length === 0
    ) {
      newErrors["shortDescription"] = t("Short Description is required");
      setTab("1");
    }

    if (!pendingAssetId && assetData.assetTypeId === null) {
      newErrors["assetTypeId"] = t("Asset Type is required");
      setTab("1");
    }
    if (assetData.assetConditionTypeId === null) {
      newErrors["assetConditionTypeId"] = t("Asset Condition is required");
      setTab("1");
    }
    if (assetData.assetStatusTypeId === null) {
      newErrors["assetStatusTypeId"] = t("Asset Status is required");
      setTab("1");
    }
    if (assetData.custodianEmployeeId === null) {
      newErrors["custodianEmployeeId"] = t("Custodian is required");
      setTab("1");
    }
    if (assetData.assetTypeId == null) {
      newErrors["assetTypeId"] = t("Asset Type is required");
      setTab("1");
    }

    if (assetData.assetCode.length === 0) {
      newErrors["assetCode"] = t("Asset Code is required");
      setTab("1");
    } else if (assetData.assetCode.length > 10) {
      newErrors["assetCode"] = t("Asset Code must be less than 10 characters");
      setTab("1");
    }


    if (assetData.barCode.length === 0) {
      newErrors["barCode"] = t("BarCode is required");
      setTab("1");
    }
    
    
    else if (assetData.departmentCode == null) {
      newErrors["departmentCode"] = t("Department is required");
      setTab("2");
    } else if (assetData.l1LocationId == null) {
      newErrors["l1LocationId"] = t("Location is required");
      setTab("2");
    } else if (assetData.l1CategoryId == null) {
      newErrors["l1CategoryId"] = t("Category is required");
      setTab("3");
    } else if (assetData.manufacturerSerialCode?.length > 10) {
      newErrors["serialCode"] = t(
        "Serial Code must be less than 10 characters"
      );
      setTab("4");
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = (withApprove: boolean) => {
    if (validateForm()) {
      // const form_data = new FormData();
      // for (const key in assetData) {
      //   if (assetData[key as keyof typeof assetData] !== null) {
      //     if (key === "assetPictures") {
      //       assetData[key].forEach((picture: any, index: any) => {
      //         for (const pictureKey in picture) {
      //           form_data.append(
      //             `${key}[${index}][${pictureKey}]`,
      //             picture[pictureKey]
      //           );
      //         }
      //       });
      //     }
      //      else if (key === "entryDate") {
      //       form_data.append(
      //         key,
      //         new Date(assetData[key as keyof typeof assetData]).toISOString()
      //       );
      //     } else {
      //       form_data.append(key, assetData[key as keyof typeof assetData]);
      //     }
      //   }
      // }
      setIsSaving(true);
      if (pendingAssetId && !isChanged) {
        confirmApproveAsset();
      } else if (pendingAssetId) {
        const { assetPictures, entryDate, ...rest } = assetData;
        updatePendingAsset({
          ...rest,
          entryDate: new Date(entryDate).toISOString(),
        })
          .then(() => {
            if (withApprove) {
              confirmApproveAsset();
            } else {
              setInfo(t("Pending asset updated successfully"));
              handleClose(true);
            }
          })
          .catch((err) => setError(err))
          .finally(() => setIsSaving(false));
      }
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setAssetData({
      ...assetData,
      [name]: type == "checkbox" ? checked : value,
    });
  };
  const handleDateChange = (value: any, name: any) => {
    setIsChanged(true);
    setAssetData({
      ...assetData,
      [name]: value,
    });
  };
  const [tab, setTab] = useState("1");
  const handleTabChange = (event: any, newValue: any) => setTab(newValue);
  const [open, setOpen] = useState(false);
  const onClose = () => {
    if (isChanged) setOpen(true);
    else props.handleClose();
  };

  const [isSaving, setIsSaving] = useState(false);

  const [openAssetConfirmModal, setOpenAssetConfirmModal] = useState(false);
  const [assetConfirmModalLabel, setAssetConfirmModalLabel] = useState("");
  const [confirmClickFn, setConfirmClickFn] = useState<any>(null);

  const handleCloseAssetConfirmModal = () => {
    setAssetConfirmModalLabel("");
    setOpenAssetConfirmModal(false);
  };

  const onApproveAsset = async () => {
    handleCloseAssetConfirmModal();
    onSave(true);
  };

  const confirmApproveAsset = () => {
    approvePendingAsset(pendingAssetId)
      .then(() => {
        setInfo(t("Asset approved successfully"));
        handleClose(true);
      })
      .catch((err) => setError(err))
      .finally(() => setIsSaving(false));
  };

  const handleOpenAssetConfirmModal = (confirmClickFn: any, label: any) => {
    setOpenAssetConfirmModal(true);
    setConfirmClickFn(() => confirmClickFn);
    setAssetConfirmModalLabel(label);
  };

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      fullWidth
      maxWidth={"xl"}
      disableEscapeKeyDown
    >
      {open && (
        <ConfirmModal
          open={open}
          onYesClick={props.handleClose}
          onNoClick={() => setOpen(false)}
        />
      )}
      {openAssetConfirmModal && (
        <PendingAssetConfirmModal
          open={openAssetConfirmModal}
          onConfirmClick={confirmClickFn}
          label={assetConfirmModalLabel}
          onCancelClick={handleCloseAssetConfirmModal}
        />
      )}
      <DialogTitle sx={{ textAlign: "center" }}>
        {pendingAssetId ? t("Edit Pending Asset") : t("Create Pending Asset")}
      </DialogTitle>
      <DialogContent>
        {isLoading && (
          <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
        )}

        <BoxDX sx={{ typography: "body1" }}>
          <TabContext value={tab}>
            <BoxDX sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
              >
                <Tab iconPosition="start" label={t("Details")} value={"1"} />
                <Tab iconPosition="start" label={t("Location")} value={"2"} />
                <Tab iconPosition="start" label={t("Category")} value={"3"} />
                <Tab
                  iconPosition="start"
                  label={t("Manufacturer")}
                  value={"4"}
                />
              </TabList>
            </BoxDX>
            <TabPanel value="1" sx={{ px: 0 }}>
              <DetailsTab
                assetData={assetData}
                handleInputChange={handleInputChange}
                usersDropDownData={usersDropDownData}
                departmentsDropDownData={departmentsDropDownData}
                assetConditionTypesDropDownData={
                  assetConditionTypesDropDownData
                }
                assetTypesDropDownData={assetTypesDropDownData}
                custodyTypesDropDownData={custodyTypesDropDownData}
                assetStatusTypesDropDownData={assetStatusTypesDropDownData}
                custodianDropDownData={custodianDropDownData}
                errors={errors}
                pendingAssetId={pendingAssetId}
                handleDateChange={handleDateChange}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ px: 0 }}>
              <LocationTab
                assetData={assetData}
                setAssetData={setAssetData}
                setIsChanged={setIsChanged}
                handleInputChange={handleInputChange}
                departmentsDropDownData={departmentsDropDownData}
                errors={errors}
                locations={locations}
              />
            </TabPanel>
            <TabPanel value="3" sx={{ px: 0 }}>
              <CategoryTab
                assetData={assetData}
                setAssetData={setAssetData}
                setIsChanged={setIsChanged}
                handleInputChange={handleInputChange}
                errors={errors}
                categories={categories}
              />
            </TabPanel>
            <TabPanel value="4" sx={{ px: 0 }}>
              <ManufacturerTab
                assetData={assetData}
                handleInputChange={handleInputChange}
                errors={errors}
              />
            </TabPanel>
          </TabContext>
        </BoxDX>
      </DialogContent>

      <DialogActions>
        <GridDX
          container
          width={"100%"}
          sx={{ justifyContent: "space-around" }}
        >
          <GridDX xs={12} md={4} mt={1} justifyContent="center">
            <ButtonDX
              variant="outlined"
              onClick={onClose}
              sx={{ mb: { xs: 2, sm: "auto" } }}
            >
              {t("Cancel")}
            </ButtonDX>
          </GridDX>
          <GridDX xs={12} md={4} mt={1} justifyContent="center">
            <LoadingButtonDX onClick={() => onSave(false)} loading={isSaving}>
              {t("Save")}
            </LoadingButtonDX>
          </GridDX>
          {pendingAssetId && (
            <GridDX xs={12} md={4} mt={1} justifyContent="center">
              <LoadingButtonDX
                onClick={() =>
                  handleOpenAssetConfirmModal(onApproveAsset, "Approve")
                }
                loading={isSaving}
              >
                {t("Update and Approve")}
              </LoadingButtonDX>
            </GridDX>
          )}
        </GridDX>
      </DialogActions>
    </Dialog>
  );
};

export default PendingAssetTabsModal;
