import { useState } from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { Box, CssBaseline, Avatar } from "@mui/material";

import MuiDrawer from "@mui/material/Drawer";

import { AccountBox } from "@mui/icons-material";
import MenuItems from "./menuitems";

const drawerWidth = 280; //306;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  position: "relative",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(11)} + 1px) !important`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  position: "relative",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const [open, setOpen] = useState(true);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: { border: 0, backgroundColor: "#3a3939" },
        }}
        sx={{
          display: { xs: "none", md: "block" },
        }}
        onMouseOver={() => setOpen(true)}
        onMouseOut={() => setOpen(false)}
      >
        <MenuItems open={open} />
      </Drawer>
    </Box>
  );
}

const UserPicture = (props: any) => {
  if (props.profilePicture)
    return (
      <Avatar
        style={{
          height: "45px",
          width: "45px",
          display: "flex",
          margin: "5%",
          fontSize: "45px",
        }}
        src={props.profilePicture}
      />
    );
  else return <AccountBox />;
};

export const ProfilePicture = UserPicture;
