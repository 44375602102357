import { useEffect, useState } from "react";
import AddEditModalDX from "../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../controls/textfielddx";
import GridDX from "../layout/griddx";
import { useNotificationContext } from "../../context/notificationcontext";
import Loading from "../loading";
import useCategoryService from "../../shared/services/categoryservice";

const AddEditCategoryModal = (props: any) => {
  const { open, handleClose, parentNode, editNode } = props;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";

  const { setError, setInfo } = useNotificationContext();
  const { addCategory, updateCategory } = useCategoryService();

  const defaultValues = {
    categoryId: 0,
    name: "",
    levelNumber: parentNode?.levelNumber + 1 || 1,
    parentCategoryId: parentNode?.id || 0,
  };

  const [nodeData, setNodeData] = useState(defaultValues);

  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (editNode) {
      const { children, ...rest } = editNode;
      setNodeData({ ...rest, categoryId: editNode.id });
    }
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    // if (nodeData.enName.length === 0)
    //   newErrors["enName"] = t("English name is required");
    // if (nodeData.arName.length === 0)
    //   newErrors["arName"] = t("Arabic name is required");
    if (nodeData.name.length === 0) newErrors["name"] = t("Name is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      setIsLoading(true);

      const operation = editNode
        ? updateCategory(nodeData)
        : addCategory(nodeData);

      operation
        .then((res) => {
          setInfo(
            t(
              editNode
                ? "Category updated successfully"
                : "Category added successfully"
            )
          );

          handleClose(true);
        })
        .catch((err) => {
          setError(t("Error adding Category"));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setNodeData({
      ...nodeData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={editNode ? t("Edit Category") : t("Add Category")}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Name")}
            name="name"
            value={nodeData.name}
            onChange={handleInputChange}
            error={errors["name"]}
          />
        </GridDX>

        {/* <GridDX item xs={12}>
          <TextFieldDX
            label={t("English Name")}
            name="enName"
            value={nodeData.enName}
            onChange={handleInputChange}
            error={errors["enName"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Arabic Name")}
            name="arName"
            value={nodeData.arName}
            onChange={handleInputChange}
            error={errors["arName"]}
          />
        </GridDX>
         */}
      </GridDX>
    </AddEditModalDX>
  );
};

export default AddEditCategoryModal;
