import { useEffect, useState } from "react";
import AddEditModalDX from "../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../controls/textfielddx";
import GridDX from "../layout/griddx";
import CheckBoxDX from "../controls/checkboxdx";
import { useNotificationContext } from "../../context/notificationcontext";
import useUserService from "../../shared/services/userservices";
import SelectListDX from "../controls/selectlistdx";
import AutoCompleteMultiple from "../controls/autocompletemultiple";
import Loading from "../loading";
import { RegularExpressions, validatePassword } from "../../shared/globals";
import { useAuthContext } from "../../context/authcontext";

const UserModal = (props: any) => {
  const authContext = useAuthContext();
  const { userId, open, handleClose } = props;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";

  const { setError, setInfo } = useNotificationContext();
  const { getActiveUsers, addUser, updateUser, getUserById } = useUserService();
  // const { getUserGroups } = useUserGroupService();
  const defaultValues = {
    username: "",
    password: "",
    fullName: "",
    isActive: true,
    isAdmin: false,
    roleIds: [],
    //for put api only
    oldPassword: "",
    email: "",
  };

  const [userData, setUserData] = useState<any>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    setIsLoading(true);
    const p1 = userId ? getUserById(userId) : null;
    Promise.all([p1])
      .then(([user]) => {
        console.log("user", user);

        if (userId) {
          const { roles, ...rest } = user;
          setUserData({
            ...rest,
            password: "",
            roleIds: [],
          });
        }
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (userData.fullName.length === 0)
      newErrors["fullName"] = t("Name is required");
    if (!userData.id && userData.username?.length === 0)
      newErrors["username"] = t("User name is required");
    if (userData.password !== "") {
      const error = validatePassword(userData.password);
      if (error !== "") newErrors["password"] = t(error);
    } else if (!userId && userData.password.length === 0)
      newErrors["password"] = t("Password is required");
    if (userData.id && userData.email.length === 0)
      newErrors["email"] = t("Email is required");
    else if (userData.id && !RegularExpressions.emailAddress.test(userData.email))
      newErrors["email"] = t("Invalid email address");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);

    const { roleIds } = userData;
    const roles = roleIds.map((e: any) => e.value);
    const operation = userId
      ? updateUser(userId, { ...userData, roleIds: roles })
      : addUser({ ...userData, roleIds: roles });
    operation
      .then(() => {
        setInfo(
          t(userId ? "User updated successfully" : "User created successfully")
        );
        handleClose(true);
      })
      .catch((err) => {
        if (err == "USER_ALREADY_EXISTS") {
          setError(
            t(`Email`) + " " + userData.email + " " + t("is already taken")
          );
        } else {
          setError(err);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setUserData({
      ...userData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={userId ? t("Edit User") : t("Create User")}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}

      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        {!userData.id && (
          <GridDX item xs={12}>
            <TextFieldDX
              label={t("User Name")}
              name="username"
              value={userData.username}
              onChange={handleInputChange}
              error={errors["username"]}
            />
          </GridDX>
        )}
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Full Name")}
            name="fullName"
            value={userData.fullName}
            onChange={handleInputChange}
            error={errors["fullName"]}
          />
        </GridDX>

        {userData.id && (
          <>
            <GridDX item xs={12} md={6}>
              <TextFieldDX
                label={t("Email")}
                name="email"
                value={userData.email}
                onChange={handleInputChange}
                error={errors["email"]}
              />
            </GridDX>

            <GridDX item  xs={12} md={6}>
              <TextFieldDX
                label={t("Old Password")}
                name="oldPassword"
                value={userData.oldPassword}
                onChange={handleInputChange}
                error={errors["oldPassword"]}
              />
            </GridDX>
          </>
        )}

        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("New Password")}
            name="password"
            value={userData.password}
            onChange={handleInputChange}
            error={errors["password"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <CheckBoxDX
            name="isActive"
            label={t("Active")}
            checked={userData.isActive}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12}>
          <CheckBoxDX
            name="isAdmin"
            label={t("This user is a system admin")}
            checked={userData.isAdmin}
            onChange={handleInputChange}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default UserModal;
