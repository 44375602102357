import useSecureAPI from "../hooks/usesecureapi";

const useAssetPictureService = () => {
  const secureAPI = useSecureAPI();

  const getAssetPicturesByAssetId = async (id: number) => {
    const response = await secureAPI.get(`AssetPicture/byasset/${id}`);
    return response.data;
  };
  const getAssetPictureById = async (id: number) => {
    const response = await secureAPI.get(`AssetPicture/${id}`);
    return response.data;
  };

  const addAssetPicture = async (data: any) => {
    const response = await secureAPI.post(`AssetPicture`, data);
    return response.data;
  };

  const deleteAssetPicture = async (id: number) => {
    const response = await secureAPI.delete(`AssetPicture/${id}`);
    return response.data;
  };

  return {
    getAssetPicturesByAssetId,
    getAssetPictureById,
    addAssetPicture,
    deleteAssetPicture,
  };
};

export default useAssetPictureService;
