import { useEffect, useState } from "react";
import AddEditLocationModal from "../../components/addeditmodals/addEditTreeLocationModal";
import { useNotificationContext } from "../../context/notificationcontext";
import TreeViewDX from "../../components/treeviewDX";
import useLocationService from "../../shared/services/locationservice";
import { useTranslation } from "react-i18next";
import Loading from "../../components/loading";

const LocationsPage = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setInfo, setError } = useNotificationContext();
  const { getLocation, deleteLocation } = useLocationService();
  const [data, setData] = useState<any>([]);
  const [parentNode, setParentNode] = useState(null);
  const [editNode, setEditNode] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    getData();
  }, []);

  const onDelete = (id: number) => {
    deleteLocation(id)
      .then((res) => {
        setInfo("Deleted Successfully");
        getData();
      })
      .catch((err) => {
        setError("Error Deleting", err);
        console.log(err, "err");
      });
  };
  const onEdit = (node: any) => {
    setEditNode(node);
    setOpen(!open);
  };
  const toCreate = (parentNode: any) => {
    setParentNode(parentNode);
    setOpen(!open);
  };
  const onClose = (refreshPage: boolean = false) => {
    setEditNode(null);
    setParentNode(null);
    setOpen(!open);
    if (refreshPage) getData();
  };

  const getData = () => {
    setLoading(true);

    getLocation()
      .then((allLocations) => {
        const parentRes = allLocations.find(
          (item: any) => item.parentLocationId === null
        );
        getLocationByParent(parentRes, allLocations);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLocationByParent = (parent: any, allLocations: any) => {
    const childrenRes = parent.locationId
      ? allLocations.filter(
          (item: any) => item.parentLocationId == parent.locationId
        )
      : allLocations.filter((item: any) => item.parentLocationId === parent.id);

    const updatedRes = childrenRes.map((item: any) => {
      const { locationId, ...rest } = item;
      return { id: locationId, ...rest };
    });

    setData((prevData: any) => {
      if (parent.parentLocationId === null) {
        const { locationId, ...rest } = parent;
        return [{ id: locationId, ...rest, children: updatedRes }];
      } else {
        const updatedData = parent.locationId
          ? updateData(prevData[0]?.children, parent.locationId, updatedRes)
          : updateData(prevData[0]?.children, parent.id, updatedRes);
        return [{ ...prevData[0], children: updatedData }];
      }
    });

    updatedRes.forEach((item: any) => {
      getLocationByParent(item, allLocations); // Pass the current child as the new parent
    });
  };

  const updateData: any = (
    prevChildren: any[],
    parentId: number,
    updatedRes: any
  ) => {
    return prevChildren.map((item: any) => {
      if (item.id === parentId) {
        return { ...item, children: updatedRes };
      }
      if (item.children) {
        return {
          ...item,
          children: updateData(item.children, parentId, updatedRes),
        };
      }
      return item;
    });
  };
  if (loading) return <Loading/>;
  else
    return (
      <>
        {open && (
          <AddEditLocationModal
            open={open}
            handleClose={onClose}
            parentNode={parentNode}
            editNode={editNode}
          />
        )}

        <TreeViewDX
          data={data}
          toCreate={toCreate}
          onEdit={onEdit}
          onDelete={onDelete}
          name={t("location")}
        />
      </>
    );
};

export default LocationsPage;
