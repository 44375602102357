import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

import { useNotificationContext } from "../../context/notificationcontext";
import ListPageDX from "../../components/business/listpagedx";
import usePendingAssetService from "../../shared/services/pendingassetservice";
import { DateFormatter } from "../../shared/globals";
import PendingAssetTabsModal from "../../components/addeditmodals/pendingassetmodaltabs/pendingassettabsmodal";
import GridDX from "../../components/layout/griddx";
import AlertComponenet from "../../components/alerts/alert";

const PendingAssets = () => {
  const { getPendingAssets, deletePendingAsset, approveBulkPendingAssets } =
    usePendingAssetService();
  const { t, i18n } = useTranslation();
  const { setInfo, setError } = useNotificationContext();
  const [pendingAssetId, setPendingAssetId] = useState<any>(null);
  const [rows, setRows] = useState<any>([]);
  const [assets, setAssets] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [showApprovalDialog, setShowApprovalDialog] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const columns: GridColDef[] = [
    {
      field: "assetCode",
      headerName: `${t("Asset Code")}`,
      flex: 1,
    },
    {
      field: "enteredByUserName",
      headerName: `${t("Added By")}`,
      flex: 1,
    },
    {
      field: "shortDescription",
      headerName: `${t("Short Description")}`,
      flex: 1,
    },

    {
      field: "entryDate",
      headerName: `${t("Entry Date")}`,
      flex: 1,
      valueGetter(params) {
        return DateFormatter(params.row.entryDate, 0, 0, 0);
      },
    },
  ];

  const getData = async () => {
    setIsLoading(true); // commented out due to error from server side
    return getPendingAssets() // api to fetch data types from the server
      .then((response) => {
        setAssets(response);
        setRows(response);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (pendingAssetId: number) => {
    setIsLoading(true);
    deletePendingAsset(pendingAssetId)
      .then(() => {
        setInfo(t("Pending asset deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (asset: any) => {
    setPendingAssetId(asset.pendingAssetId);
    setOpen(!open);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setPendingAssetId(null);
    setOpen(!open);

    if (refreshPage) getData();
  };

  useEffect(() => {
    getData();
  }, [i18n.language]);

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(assets);
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const newRows = assets.filter(
      ({ assetCode, enteredByUserName, shortDescription, entryDate }: any) =>
        assetCode?.toLowerCase().includes(lowercasedValue) ||
        enteredByUserName?.toLowerCase().includes(lowercasedValue) ||
        shortDescription?.toLowerCase().includes(lowercasedValue) ||
        entryDate?.toLowerCase().includes(lowercasedValue)
    );
    setRows(newRows);
  };

  const handleApprove = (ids: any) => {
    setSelectedIds(ids);
    setShowApprovalDialog(true);
  };

  const onConfirmApprove = () => {
    setShowApprovalDialog(false);
    setIsLoading(true);
    approveBulkPendingAssets(selectedIds)
      .then(() => {
        return getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onCancelApprove = () => {
    setShowApprovalDialog(false);
    setSelectedIds([]);
  };

  return (
    <GridDX container sx={{ width: "100%" }}>
      {open && (
        <PendingAssetTabsModal
          open={open}
          handleClose={onClose}
          pendingAssetId={pendingAssetId}
        />
      )}
      {showApprovalDialog && (
        <AlertComponenet
          open={showApprovalDialog}
          title={"Confirm Approval"}
          okLabel={"Yes"}
          closeLabel={"No"}
          alert={`Do you want to approve ${selectedIds.length} pending assets?`}
          handleClose={onCancelApprove}
          handleCloseOk={onConfirmApprove}
        />
      )}
      <GridDX item xs={12}>
        <ListPageDX
          listTitle={t("Pending Assets")}
          name={t("pending asset")}
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row.pendingAssetId}
          isLoading={isLoading}
          checkboxSelection={true}
          onEdit={onEdit}
          onDelete={onDelete}
          setGridFilterCriteria={setGridFilterCriteria}
          customActions={[
            {
              label: t("Approve"),
              onClick: handleApprove,
            },
          ]}
        />
      </GridDX>
    </GridDX>
  );
};

export default PendingAssets;
