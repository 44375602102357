import React from "react";
import GridDX from "../../layout/griddx";
import TextFieldDX from "../../controls/textfielddx";
import { useTranslation } from "react-i18next";

const ManufacturerTab = (props: any) => {
  const { assetData, handleInputChange, errors } = props;

  const { t } = useTranslation();

  return (
    <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
      <GridDX item xs={12}>
        <TextFieldDX
          label={t("Manufacturer Code")}
          name="manufacturerCode"
          value={assetData?.manufacturerCode}
          onChange={handleInputChange}
          error={errors["manufacturerCode"]}
        />
      </GridDX>
      <GridDX item xs={6}>
        <TextFieldDX
          label={t("Name")}
          name="manufacturerName"
          value={assetData?.manufacturerName}
          onChange={handleInputChange}
          error={errors["manufacturerName"]}
        />
      </GridDX>

      <GridDX item xs={6}>
        <TextFieldDX
          label={t("Serial Code")}
          name="manufacturerSerialCode"
          value={assetData?.manufacturerSerialCode}
          onChange={handleInputChange}
          error={errors["manufacturerSerialCode"]}
        />
      </GridDX>
      <GridDX item xs={6}>
        <TextFieldDX
          label={t("Brand")}
          name="manufacturerBrand"
          value={assetData?.manufacturerBrand}
          onChange={handleInputChange}
          error={errors["manufacturerBrand"]}
        />
      </GridDX>
      <GridDX item xs={6}>
        <TextFieldDX
          label={t("Model")}
          name="manufacturerModel"
          value={assetData?.manufacturerModel}
          onChange={handleInputChange}
          error={errors["manufacturerModel"]}
        />
      </GridDX>
      <GridDX item xs={6}>
        <TextFieldDX
          label={t("Specification")}
          name="manufacturerSpecification"
          value={assetData?.manufacturerSpecification}
          onChange={handleInputChange}
          error={errors["manufacturerSpecification"]}
        />
      </GridDX>
      <GridDX item xs={6}>
        <TextFieldDX
          label={t("Size")}
          name="manufacturerSize"
          value={assetData?.manufacturerSize}
          onChange={handleInputChange}
          error={errors["manufacturerSize"]}
        />
      </GridDX>
    </GridDX>
  );
};

export default ManufacturerTab;
