import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ListPageDX from "../../components/business/listpagedx";
import { useAuthContext } from "../../context/authcontext";
import { useNotificationContext } from "../../context/notificationcontext";
import DepartmentModal from "../../components/addeditmodals/departmentModal";
import useDepartmentService from "../../shared/services/departmentservice";

const Departments = () => {
  // const { userData } = useAuthContext();
  // const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const { getDepartments, deleteDepartment } = useDepartmentService();
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setInfo, setError } = useNotificationContext();
  const [departmentCode, setDepartmentCode] = useState<any>(null);
  const [rows, setRows] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "departmentCode",
      headerName: `${t("Code")}`,
      flex: 1,
    },
    {
      // field: languageIsEn ? "enName" : "arName",
      field: "name",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: "integrationCode",
      headerName: `${t("Integration Code")}`,
      flex: 1,
    },
   
  ];

  const getData = () => {
    setIsLoading(true); // commented out due to error from server side
    getDepartments() // api to fetch data types from the server
      .then((response) => {
        setDepartments(response);
        setRows(response);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (deparmentCode: string) => {
    setIsLoading(true);
    deleteDepartment(deparmentCode)
      .then(() => {
        setInfo(t("Department deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (department: any) => {
    setDepartmentCode(department.departmentCode);
    setOpen(!open);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setDepartmentCode(null);
    setOpen(!open);

    if (refreshPage) getData();
  };

  useEffect(() => {
    getData();
  }, [i18n.language]);

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(departments);
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const newRows = departments.filter(
      ({ code, name, integrationCode }: any) =>
        code?.toLowerCase().includes(lowercasedValue) ||
        name?.toLowerCase().includes(lowercasedValue) ||
        integrationCode?.toLowerCase().includes(lowercasedValue)
      // languageIsEn
      //   ? enName?.toLowerCase().includes(lowercasedValue) ||
      //     createdByUser?.enFullName?.toLowerCase().includes(lowercasedValue)
      //   : arName?.includes(value) ||
      //     createdByUser?.arFullName?.includes(value)
    );
    setRows(newRows);
  };
  return (
    <>
      {open && (
        <DepartmentModal
          open={open}
          handleClose={onClose}
          departmentCode={departmentCode}
        />
      )}
      <ListPageDX
        listTitle={t("Departments")}
        name={t("department")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.departmentCode}
        isLoading={isLoading}
        toCreate={toCreate}
        onEdit={onEdit}
        onDelete={onDelete}
        setGridFilterCriteria={setGridFilterCriteria}
      />
    </>
  );
};

export default Departments;
