import useSecureAPI from "../hooks/usesecureapi";

const usePendingAssetService = () => {
  const secureAPI = useSecureAPI();

  const getPendingAssets = async () => {
    const response = await secureAPI.get(`PendingAsset`);
    return response.data;
  };
  const getPendingAssetById = async (id: number) => {
    const response = await secureAPI.get(`PendingAsset/${id}`);
    return response.data;
  };

  const addPendingAsset = async (data: any) => {
    const response = await secureAPI.post(`PendingAsset`, data);
    return response.data;
  };

  const updatePendingAsset = async (data: any) => {
    const response = await secureAPI.put(`PendingAsset`, data);
    return response.data;
  };

  const deletePendingAsset = async (id: number) => {
    const response = await secureAPI.delete(`PendingAsset/${id}`);
    return response.data;
  };

  const approvePendingAsset = async (id: number) => {
    const response = await secureAPI.put(`PendingAsset/approve/${id}`);
    return response.data;
  };

  const approveBulkPendingAssets = async (data: number[]) => {
    const response = await secureAPI.put(`PendingAsset/approvebulk`, data);
    return response.data;
  };

  return {
    addPendingAsset,
    deletePendingAsset,
    updatePendingAsset,
    getPendingAssets,
    getPendingAssetById,
    approvePendingAsset,
    approveBulkPendingAssets,
  };
};

export default usePendingAssetService;
