import React from "react";
import GridDX from "../../layout/griddx";
import TextFieldDX from "../../controls/textfielddx";
import { useTranslation } from "react-i18next";
import DatePickerDX from "../../controls/datepickerdx";
import SelectListDX from "../../controls/selectlistdx";
import CheckBoxDX from "../../controls/checkboxdx";
import UploadBoxDX from "../../controls/uploadboxdx";

const DetailsTab = (props: any) => {
  const {
    assetData,
    handleInputChange,
    errors,
    usersDropDownData,
    categoriesDropDownData,
    departmentsDropDownData,
    assetConditionTypesDropDownData,
    assetTypesDropDownData,
    custodyTypesDropDownData,
    custodianDropDownData,
    handleImageChange,
    handleImageDelete,
    assetStatusTypesDropDownData,
    pendingAssetId,
    handleDateChange
  } = props;
  const { t } = useTranslation();
  return (
    <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
      <GridDX item xs={6}>
        <TextFieldDX
          label={t("Asset Code")}
          name="assetCode"
          value={assetData.assetCode}
          onChange={handleInputChange}
          error={errors["assetCode"]}
        />
      </GridDX>

      <GridDX item xs={6}>
        <SelectListDX
          items={assetStatusTypesDropDownData}
          name="assetStatusTypeId"
          label={t("Status")}
          onChange={handleInputChange}
          value={assetData.assetStatusTypeId}
          error={errors["assetStatusTypeId"]}
          InputLabelProps={{
            shrink: assetData.assetStatusTypeId !== null, // Set to true if there's a value just to handle label position
          }}
        />
      </GridDX>
      <GridDX item xs={6}>
        <TextFieldDX
          label={t("Short Description")}
          name="shortDescription"
          value={assetData.shortDescription}
          onChange={handleInputChange}
          error={errors["shortDescription"]}
        />
      </GridDX>
      <GridDX item xs={6}>
        <SelectListDX
          items={custodianDropDownData}
          name="custodianEmployeeId"
          label={t("Custodian")}
          onChange={handleInputChange}
          value={assetData.custodianEmployeeId}
          error={errors["custodianEmployeeId"]}
          InputLabelProps={{
            shrink: assetData.custodianEmployeeId !== null, // Set to true if there's a value just to handle label position
          }}
        />
      </GridDX>

      <GridDX item xs={6}>
        <SelectListDX
          items={custodyTypesDropDownData}
          name="custodyTypeId"
          label={t("Custody Type")}
          onChange={handleInputChange}
          value={assetData.custodyTypeId}
          error={errors["custodyTypeId"]}
          InputLabelProps={{
            shrink: assetData.custodyTypeId !== null, // Set to true if there's a value just to handle label position
          }}
        />
      </GridDX>
      <GridDX item xs={6}>
        <DatePickerDX
          label={t("Entry Date")}
          sx={{ flex: 1 }}
          name="entryDate"
          value={assetData.entryDate}
          onChange={(value: any) => handleDateChange(value, "entryDate")}
          error={errors["entryDate"]}
          format="DD-MM-YYYY"
        />
      </GridDX>
      <GridDX item xs={6}>
        <SelectListDX
          items={usersDropDownData}
          name="enteredByUserId"
          label={t("Enter By")}
          onChange={handleInputChange}
          value={assetData.enteredByUserId}
          error={errors["enteredByUserId"]}
          InputLabelProps={{
            shrink: assetData.enteredByUserId !== null, // Set to true if there's a value just to handle label position
          }}
        />
      </GridDX>
      <GridDX item xs={6}>
        <SelectListDX
          items={assetConditionTypesDropDownData}
          name="assetConditionTypeId"
          label={t("Asset Condition")}
          onChange={handleInputChange}
          value={assetData.assetConditionTypeId}
          error={errors["assetConditionTypeId"]}
          InputLabelProps={{
            shrink: assetData.assetConditionTypeId !== null, // Set to true if there's a value just to handle label position
          }}
        />
      </GridDX>
      <GridDX item xs={6}>
        <SelectListDX
          items={assetTypesDropDownData}
          name="assetTypeId"
          label={t("Asset Type")}
          onChange={handleInputChange}
          value={assetData.assetTypeId}
          error={errors["assetTypeId"]}
          InputLabelProps={{
            shrink: assetData.assetTypeId !== null, // Set to true if there's a value just to handle label position
          }}
        />
      </GridDX>

      <GridDX item xs={6}>
        <TextFieldDX
          label={t("Old Tag")}
          name="oldTag"
          value={assetData.oldTag}
          onChange={handleInputChange}
          error={errors["oldTag"]}
        />
      </GridDX>
      <GridDX item xs={6}>
        <TextFieldDX
          label={t("Bar Code")}
          name="barCode"
          value={assetData.barCode}
          onChange={handleInputChange}
          error={errors["barCode"]}
        />
      </GridDX>
      <GridDX item xs={12}>
        <CheckBoxDX
          name="isTangible"
          label={t("Tangible Asset")}
          checked={assetData.isTangible}
          onChange={handleInputChange}
        />
      </GridDX>

      {/* <GridDX item xs={12} justifyContent="center">
        <UploadBoxDX
          label={t("Picture")}
          image={assetData?.assetPictures[0]?.uploadLocation}
          setImage={handleImageChange}
          handleDelete={handleImageDelete}
        />
      </GridDX> */}
    </GridDX>
  );
};

export default DetailsTab;
