import useSecureAPI from "../hooks/usesecureapi";

const useDepartmentService = () => {
  const secureAPI = useSecureAPI();

  const getDepartments = async () => {
    const response = await secureAPI.get(`Department`);
    return response.data;
  };
  const getDepartmentByDepCode = async (code: string) => {
    const response = await secureAPI.get(`Department/${code}`);
    return response.data;
  };

  const addDepartment = async (data: any) => {
    const response = await secureAPI.post(`Department`, data);
    return response.data;
  };

  const updateDepartment = async (data: any) => {
    const response = await secureAPI.put(`Department`, data);
    return response.data;
  };

  const deleteDepartment = async (code: string) => {
    const response = await secureAPI.delete(`Department/${code}`);
    return response.data;
  };

  return {
    addDepartment,
    deleteDepartment,
    updateDepartment,
    getDepartments,
    getDepartmentByDepCode,
  };
};

export default useDepartmentService;
