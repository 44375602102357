import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CardContent, Chip, Divider, Typography } from "@mui/material";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import LoadingButtonDX from "../components/controls/loadingbuttondx";
import TextFieldDX from "../components/controls/textfielddx";
import CardDX from "../components/layout/carddx";
import GridDX from "../components/layout/griddx";
import { useNotificationContext } from "../context/notificationcontext";
import { DateFormatter } from "../shared/globals";
import useAssetService from "../shared/services/assetservice";
import useDepartmentService from "../shared/services/departmentservice";
import useEmployeeService from "../shared/services/employeeservice";
import AutoCompleteMultiple from "../components/controls/autocompletemultiple";
import DatePickerDX from "../components/controls/datepickerdx";
import useUserService from "../shared/services/userservices";
import { FilterAltOutlined, PrintOutlined } from "@mui/icons-material";
import ListPageDX from "../components/business/listpagedx";
import { GridColDef } from "@mui/x-data-grid";
import TypeTranslator from "../shared/typetranslator";
import SelectListDX from "../components/controls/selectlistdx";
import useLocationService from "../shared/services/locationservice";
import useReportService from "../shared/services/reportservices";
import useCategoryService from "../shared/services/categoryservice";

const Reports = () => {
  const { t, i18n } = useTranslation();
  const { setError, setInfo } = useNotificationContext();
  const { getAssets, getAssetStatusTypes, getAssetConditionTypes } =
    useAssetService();
  const { getUsers } = useUserService();
  const { getDepartments } = useDepartmentService();
  const { getEmployees } = useEmployeeService();
  const { getLocation } = useLocationService();
  const { generateReport } = useReportService();
  const { getCategories } = useCategoryService();

  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState<any>([]);

  const [userList, setUserList] = useState([]);
  const [assetConditionTypesList, setAssetConditionTypesList] = useState([]);
  const [assetStatusTypesList, setAssetStatusTypesList] = useState([]);
  const [departmentsList, setDepartmentList] = useState([]);
  const [custodiansList, setCustodiansList] = useState([]);
  const [assetCodesList, setAssetCodesList] = useState([]);

  const { getassetStatusValue, getAssetTypeValue } = TypeTranslator();

  const defaultValues = {
    assetIds: [],
    description: null, // string
    fromEntryDate: null, // string
    toEntryDate: null, // string
    enteredByUserIds: [],
    l1CategoryId: null,
    l2CategoryIds: [],
    l3CategoryIds: [],
    l4CategoryIds: [],
    l1LocationId: null,
    l2LocationIds: [],
    l3LocationIds: [],
    l4LocationIds: [],
    l5LocationIds: [],
    assetConditionTypeIds: [],
    assetStatusTypeIds: [],
    custodianIds: [],
    departmentCodes: [],
    manufacturerSerialCode: null, // string
    manufacturerBrand: null, // string
    manufacturerModel: null, // string
  };
  const getDetails = async () => {
    setIsLoading(true);
    const p1 = getAssets();
    const p2 = getUsers();
    const p3 = getCategories();
    const p4 = getLocation();
    const p5 = getDepartments();
    // const p6 = getAssetTypes();
    const p7 = getAssetConditionTypes();
    // const p8 = getCustodyTypes();
    const p9 = getAssetStatusTypes();
    const p10 = getEmployees();
    Promise.all([p1, p2, p3, p4, p5, p7, p9, p10])
      .then(
        ([
          assets,
          users,
          categories,
          locations,
          departments,
          assetConditionTypes,
          assetStatusTypes,
          custodians,
        ]) => {
          setUserList(
            users.map((user: any) => ({ text: user.fullName, value: user.id }))
          );

          setDepartmentList(
            departments.map((res: any) => ({
              text: res.name,
              value: res.departmentCode,
            }))
          );
          setCategories(categories);
          setLocations(locations);

          setAssetConditionTypesList(
            assetConditionTypes.map((res: any) => ({
              text: res.description,
              value: res.assetConditionTypeId,
            }))
          );
          setAssetStatusTypesList(
            assetStatusTypes.map((res: any) => ({
              text: res.description,
              value: res.assetStatusTypeId,
            }))
          );
          setCustodiansList(
            custodians.map((res: any) => ({
              text: res.enFullname,
              value: res.employeeId,
            }))
          );

          const assetCodes = assets.map((res: any) => ({
            text: res.assetCode,
            value: res.assetId,
          }));
          setAssetCodesList(assetCodes);
        }
      )
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getDetails();
  }, []);

  const [isReportGenerated, setIsReportGenerated] = useState(false);
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const [filterFields, setFilterFields] = useState(defaultValues);
  const [locations, setLocations] = useState<any>([]);
  const [l1Locations, setL1Locations] = useState<any>([]);
  const [l2Locations, setL2Locations] = useState<any>([]);
  const [l3Locations, setL3Locations] = useState<any>([]);
  const [l4Locations, setL4Locations] = useState<any>([]);
  const [l5Locations, setL5Locations] = useState<any>([]);

  const [categories, setCategories] = useState<any>([]);
  const [l1Categories, setL1Categories] = useState<any>([]);
  const [l2Categories, setL2Categories] = useState<any>([]);
  const [l3Categories, setL3Categories] = useState<any>([]);
  const [l4Categories, setL4Categories] = useState<any>([]);

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setFilterFields({
      ...filterFields,
      [name]: type == "checkbox" ? checked : value,
    });
  };
  const handleDateChange = (date: any, name: any) => {
    setFilterFields({
      ...filterFields,
      [name]: date,
    });
  };
  const handleMultiSelectInputChange = (value: any, name: any) => {
    if (name === "l2LocationIds") {
      let oldl3 = filterFields.l3LocationIds;
      let updatedL3: any = [];
      // only get locations that have the selected l2 locations as parent
      updatedL3 = locations
        .filter((l: any) =>
          value?.some((i: any) => i.value == l.parentLocationId)
        )
        .map((l: any) => ({
          value: l.locationId,
          text: l.name,
        }));

      // only set those locations which were present in oldl3 and updatedL3
      if (oldl3) {
        updatedL3 = oldl3.filter((l: any) =>
          updatedL3.some((i: any) => i.value == l.value)
        );
      }

      setFilterFields({
        ...filterFields,
        [name]: value,
        l3LocationIds: updatedL3,
      });
    } else if (name === "l3LocationIds") {
      let oldl4 = filterFields.l4LocationIds;
      let updatedL4: any = [];
      // only get locations that have the selected l3 locations as parent
      updatedL4 = locations
        .filter((l: any) =>
          value?.some((i: any) => i.value == l.parentLocationId)
        )
        .map((l: any) => ({
          value: l.locationId,
          text: l.name,
        }));

      // only set those locations which were present in oldl4 and updatedL4
      if (oldl4) {
        updatedL4 = oldl4.filter((l: any) =>
          updatedL4.some((i: any) => i.value == l.value)
        );
      }
      setFilterFields({
        ...filterFields,
        [name]: value,
        l4LocationIds: updatedL4,
      });
    } else if (name === "l4LocationIds") {
      let oldl5 = filterFields.l5LocationIds;
      let updatedL5: any = [];
      // only get locations that have the selected l4 locations as parent
      updatedL5 = locations
        .filter((l: any) =>
          value?.some((i: any) => i.value == l.parentLocationId)
        )
        .map((l: any) => ({
          value: l.locationId,
          text: l.name,
        }));
      // only set those locations which were present in oldl5 and updatedL5
      if (oldl5) {
        updatedL5 = oldl5.filter((l: any) =>
          updatedL5.some((i: any) => i.value == l.value)
        );
      }
      setFilterFields({
        ...filterFields,
        [name]: value,
        l5LocationIds: updatedL5,
      });
    } else if (name === "l2CategoryIds") {
      let oldc3 = filterFields.l3CategoryIds;
      let updatedC3: any = [];
      // only get categories that have the selected l2 categories as parent
      updatedC3 = categories
        .filter((l: any) =>
          value?.some((i: any) => i.value == l.parentCategoryId)
        )
        .map((l: any) => ({
          value: l.categoryId,
          text: l.name,
        }));

      // only set those categories which were present in oldl3 and updatedL3
      if (oldc3) {
        updatedC3 = oldc3.filter((l: any) =>
          updatedC3.some((i: any) => i.value == l.value)
        );
      }

      setFilterFields({
        ...filterFields,
        [name]: value,
        l3CategoryIds: updatedC3,
      });
    } else if (name === "l3CategoryIds") {
      let oldc4 = filterFields.l4CategoryIds;
      let updatedC4: any = [];
      // only get categories that have the selected l3 categories as parent
      updatedC4 = categories
        .filter((l: any) =>
          value?.some((i: any) => i.value == l.parentCategoryId)
        )
        .map((l: any) => ({
          value: l.categoryId,
          text: l.name,
        }));

      // only set those categories which were present in oldl4 and updatedL4
      if (oldc4) {
        updatedC4 = oldc4.filter((l: any) =>
          updatedC4.some((i: any) => i.value == l.value)
        );
      }
      setFilterFields({
        ...filterFields,
        [name]: value,
        l4CategoryIds: updatedC4,
      });
    } else {
      setFilterFields({
        ...filterFields,
        [name]: value,
      });
    }
  };

  const handleGenerateReport = () => {
    setIsLoading(true);
    let dataforReport = {
      ...filterFields,
      enteredByUserIds: (filterFields.enteredByUserIds as { value: any }[]).map(
        (ids) => ids.value
      ),
      assetIds: (filterFields.assetIds as { value: any }[]).map(
        (ids) => ids.value
      ),
      l2LocationIds: (filterFields.l2LocationIds as { value: any }[]).map(
        (ids) => ids.value
      ),
      l3LocationIds: (filterFields.l3LocationIds as { value: any }[]).map(
        (ids) => ids.value
      ),
      l4LocationIds: (filterFields.l4LocationIds as { value: any }[]).map(
        (ids) => ids.value
      ),
      l5LocationIds: (filterFields.l5LocationIds as { value: any }[]).map(
        (ids) => ids.value
      ),
      l2CategoryIds: (filterFields.l2CategoryIds as { value: any }[]).map(
        (ids) => ids.value
      ),
      l3CategoryIds: (filterFields.l3CategoryIds as { value: any }[]).map(
        (ids) => ids.value
      ),
      l4CategoryIds: (filterFields.l4CategoryIds as { value: any }[]).map(
        (ids) => ids.value
      ),
      assetConditionTypeIds: (
        filterFields.assetConditionTypeIds as {
          value: any;
        }[]
      ).map((ids) => ids.value),
      assetStatusTypeIds: (
        filterFields.assetStatusTypeIds as { value: any }[]
      ).map((ids) => ids.value),
      departmentCodes: (filterFields.departmentCodes as { value: any }[]).map(
        (ids) => ids.value
      ),
      custodianIds: (filterFields.custodianIds as { value: any }[]).map(
        (ids) => ids.value
      ),
    };

    generateReport(dataforReport)
      .then((assets) => {
        setRows(assets);
        setTimeout(() => {
          if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: "smooth" });
          }
        });

        setIsReportGenerated(() => true);
      })
      .catch((err) => {
        setError(err);
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    let l1 = locations.filter((l: any) => l.parentLocationId === null);
    l1 = l1.map((l: any) => ({
      value: l.locationId,
      text: l.name,
    }));
    setL1Locations(l1);
  }, [locations]);
  useEffect(() => {
    let l2 = locations.filter(
      (l: any) =>
        l.parentLocationId === filterFields.l1LocationId && l.levelNumber === 2
    );
    l2 = l2.map((l: any) => ({
      value: l.locationId,
      text: l.name,
    }));
    setL2Locations(l2);
  }, [filterFields.l1LocationId]);

  useEffect(() => {
    let l3: any[] = [];
    if (filterFields.l2LocationIds) {
      l3 = locations.filter(
        (l: any) =>
          filterFields.l2LocationIds !== null &&
          filterFields.l2LocationIds!.some(
            (i: any) => i.value == l.parentLocationId
          ) &&
          l.levelNumber === 3
      );
      l3 = l3.map((l: any) => ({
        value: l.locationId,
        text: l.name,
      }));
    }
    setL3Locations(l3);
  }, [filterFields.l2LocationIds]);

  useEffect(() => {
    let l4 = locations.filter(
      (l: any) =>
        filterFields?.l3LocationIds &&
        filterFields?.l3LocationIds!.some(
          (i: any) => i.value == l.parentLocationId
        ) &&
        l.levelNumber === 4
    );
    l4 = l4.map((l: any) => ({
      value: l.locationId,
      text: l.name,
    }));
    setL4Locations(l4);
  }, [filterFields?.l3LocationIds]);

  useEffect(() => {
    let l5 = locations.filter(
      (l: any) =>
        filterFields?.l4LocationIds &&
        filterFields?.l4LocationIds!.some(
          (i: any) => i.value == l.parentLocationId
        ) &&
        l.levelNumber === 5
    );
    l5 = l5.map((l: any) => ({
      value: l.locationId,
      text: l.name,
    }));
    setL5Locations(l5);
  }, [filterFields?.l4LocationIds]);

  useEffect(() => {
    let c1 = categories.filter((l: any) => l.parentCategoryId === null);
    c1 = c1.map((l: any) => ({
      value: l.categoryId,
      text: l.name,
    }));
    setL1Categories(c1);
  }, [categories]);

  useEffect(() => {
    let c2 = categories.filter(
      (l: any) =>
        l.parentCategoryId === filterFields.l1CategoryId && l.levelNumber === 2
    );
    c2 = c2.map((l: any) => ({
      value: l.categoryId,
      text: l.name,
    }));
    setL2Categories(c2);
  }, [filterFields.l1CategoryId]);

  useEffect(() => {
    let c3 = categories.filter(
      (l: any) =>
        filterFields.l2CategoryIds !== null &&
        filterFields.l2CategoryIds!.some(
          (i: any) => i.value == l.parentCategoryId
        ) &&
        l.levelNumber === 3
    );
    c3 = c3.map((l: any) => ({
      value: l.categoryId,
      text: l.name,
    }));
    setL3Categories(c3);
  }, [filterFields.l2CategoryIds]);

  useEffect(() => {
    let c4 = categories.filter(
      (l: any) =>
        filterFields.l3CategoryIds !== null &&
        filterFields.l3CategoryIds!.some(
          (i: any) => i.value == l.parentCategoryId
        ) &&
        l.levelNumber === 4
    );
    c4 = c4.map((l: any) => ({
      value: l.categoryId,
      text: l.name,
    }));
    setL4Categories(c4);
  }, [filterFields.l3CategoryIds]);

  const columns: GridColDef[] = [
    {
      field: "assetCode",
      headerName: `${t("Asset Code")}`,
    },
    {
      field: "description",
      headerName: `${t("Description")}`,
      minWidth: 100,
    },
    {
      field: "shortDescription",
      headerName: `${t("Short Description")}`,
      minWidth: 100,
    },
    {
      field: "entryDate",
      headerName: `${t("Entry Date")}`,
      minWidth: 100,
      valueGetter(params) {
        return DateFormatter(params.row.entryDate, 0, 0, 0);
      },
    },
    {
      field: "assetStatusName",
      headerName: `${t("Status")}`,
      minWidth: 100,
    },
    {
      field: "assetConditionName",
      headerName: `${t("Condition")}`,
      minWidth: 100,
    },
    {
      field: "departmentName",
      headerName: `${t("Department")}`,
      minWidth: 200,
    },
    {
      field: "custodianEmployeeName",
      headerName: `${t("Custodian")}`,
      minWidth: 200,
    },
    {
      field: "l1CategoryName",
      headerName: `${t("Category")}`,
      minWidth: 200,
    },
    {
      field: "l2CategoryName",
      headerName: `${t("Sub Category")}`,
      minWidth: 100,
    },
    {
      field: "l3CategoryName",
      headerName: `${t("Group")}`,
      minWidth: 100,
    },
    {
      field: "l4CategoryName",
      headerName: `${t("Sub Group")}`,
      minWidth: 100,
    },
    {
      field: "l1LocationName",
      headerName: `${t("Country")}`,
      minWidth: 100,
    },
    {
      field: "l2LocationName",
      headerName: `${t("City")}`,
      minWidth: 100,
    },
    {
      field: "l3LocationName",
      headerName: `${t("Branch")}`,
      minWidth: 100,
    },
    {
      field: "l4LocationName",
      headerName: `${t("Location")}`,
      minWidth: 100,
    },
    {
      field: "oldTag",
      headerName: `${t("Old Tag")}`,
      minWidth: 100,
    },
    {
      field: "manufacturerModel",
      headerName: `${t("Manufacturer Model")}`,
      minWidth: 200,
    },
    {
      field: "manufacturerBrand",
      headerName: `${t("Manufacturer Brand")}`,
      minWidth: 200,
    },
    {
      field: "manufacturerCode",
      headerName: `${t("Manufacturer Code")}`,
      minWidth: 200,
    },
    {
      field: "manufacturerName",
      headerName: `${t("Manufacturer Name")}`,
      minWidth: 200,
    },
    {
      field: "manufacturerSerialCode",
      headerName: `${t("Manufacturer Serial Code")}`,
      minWidth: 200,
    },
    {
      field: "manufacturerSize",
      headerName: `${t("Manufacturer Size")}`,
      minWidth: 200,
    },
    {
      field: "manufacturerSpecification",
      headerName: `${t("Manufacturer Specification")}`,
      minWidth: 200,
    },
  ];

  return (
    <GridDX container flexDirection="column" sx={{ pt: 1 }}>
      <CardDX>
        <CardContent>
          <GridDX container>
            <GridDX item xs={4}>
              <Typography fontSize={22} gutterBottom>
                <FilterAltOutlined fontSize="medium" /> {t("Filters")}
              </Typography>
            </GridDX>
            <GridDX item xs={8} sx={{ mb: 2 }}>
              <GridDX container fullWidth justifyContent="end">
                <LoadingButtonDX
                  sx={{ mx: 1 }}
                  color="primary"
                  loading={isLoading}
                  loadingPosition="end"
                  onClick={handleGenerateReport}
                >
                  {t("Generate Report")}
                </LoadingButtonDX>
              </GridDX>
            </GridDX>
          </GridDX>
          <Divider sx={{ mb: 1 }} />
          <GridDX container fullWidth>
            <GridDX item xs={12} flexDirection="column">
              <GridDX container columnSpacing={1} rowSpacing={2} sx={{ py: 1 }}>
                <GridDX item xs={12}>
                  <AutoCompleteMultiple
                    sx={{ width: "100%" }}
                    label={t("Entered By")}
                    name={"enteredByUserIds"}
                    placeholder={"Entered By"}
                    list={userList}
                    value={filterFields.enteredByUserIds}
                    onChange={(value: any) =>
                      handleMultiSelectInputChange(value, "enteredByUserIds")
                    }
                  />
                </GridDX>
                <GridDX item xs={12}>
                  <AutoCompleteMultiple
                    sx={{ width: "100%" }}
                    label={t("Asset Codes")}
                    name={"assetIds"}
                    placeholder={"Asset Code"}
                    list={assetCodesList}
                    value={filterFields.assetIds}
                    onChange={(value: any) =>
                      handleMultiSelectInputChange(value, "assetIds")
                    }
                  />
                </GridDX>

                <GridDX item xs={12}>
                  <TextFieldDX
                    label={t("Description")}
                    name="description"
                    placeholder={t("Description")}
                    value={filterFields.description}
                    onChange={handleInputChange}
                  />
                </GridDX>

                <GridDX item xs={6}>
                  <DatePickerDX
                    sx={{ width: "100%" }}
                    label={t("From Date")}
                    name="fromEntryDate"
                    format="DD-MM-YYYY"
                    value={filterFields.fromEntryDate}
                    onChange={(date: any) =>
                      handleDateChange(date, "fromEntryDate")
                    }
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <DatePickerDX
                    sx={{ width: "100%" }}
                    label={t("To Date")}
                    name="toEntryDate"
                    format="DD-MM-YYYY"
                    value={filterFields.toEntryDate}
                    onChange={(date: any) =>
                      handleDateChange(date, "toEntryDate")
                    }
                  />
                </GridDX>
              </GridDX>

              <Typography fontSize={20} sx={{ paddingTop: 2 }} gutterBottom>
                <FormatListBulletedOutlinedIcon fontSize="small" />{" "}
                {t("Location Filter")}
              </Typography>
              <Divider />

              <GridDX container columnSpacing={1} rowSpacing={2} sx={{ py: 1 }}>
                <GridDX item xs={6}>
                  <SelectListDX
                    items={l1Locations}
                    name="l1LocationId"
                    label={t("Country")}
                    onChange={handleInputChange}
                    value={filterFields.l1LocationId}
                    InputLabelProps={{
                      shrink: filterFields.l1LocationId !== null, // Set to true if there's a value just to handle label position
                    }}
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <AutoCompleteMultiple
                    sx={{ width: "100%" }}
                    label={t("City")}
                    name={"l2LocationIds"}
                    placeholder={"City"}
                    list={l2Locations}
                    value={filterFields.l2LocationIds}
                    onChange={(value: any) =>
                      handleMultiSelectInputChange(value, "l2LocationIds")
                    }
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <AutoCompleteMultiple
                    sx={{ width: "100%" }}
                    label={t("Branch")}
                    name={"l3LocationIds"}
                    placeholder={"Branch"}
                    list={l3Locations}
                    value={filterFields.l3LocationIds}
                    onChange={(value: any) =>
                      handleMultiSelectInputChange(value, "l3LocationIds")
                    }
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <AutoCompleteMultiple
                    sx={{ width: "100%" }}
                    label={t("Location")}
                    name={"l4LocationIds"}
                    placeholder={"Location"}
                    list={l4Locations}
                    value={filterFields.l4LocationIds}
                    onChange={(value: any) =>
                      handleMultiSelectInputChange(value, "l4LocationIds")
                    }
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <AutoCompleteMultiple
                    sx={{ width: "100%" }}
                    label={t("Sub Locations")}
                    name={"l5LocationIds"}
                    placeholder={"Location"}
                    list={l5Locations}
                    value={filterFields.l5LocationIds}
                    onChange={(value: any) =>
                      handleMultiSelectInputChange(value, "l5LocationIds")
                    }
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <AutoCompleteMultiple
                    sx={{ width: "100%" }}
                    label={t("Department")}
                    name={"departmentCodes"}
                    placeholder={"Department"}
                    list={departmentsList}
                    value={filterFields.departmentCodes}
                    onChange={(value: any) =>
                      handleMultiSelectInputChange(value, "departmentCodes")
                    }
                  />
                </GridDX>
              </GridDX>

              <Typography fontSize={20} sx={{ paddingTop: 2 }} gutterBottom>
                <FormatListBulletedOutlinedIcon fontSize="small" />{" "}
                {t("Category Filter")}
              </Typography>
              <Divider />
              <GridDX container columnSpacing={1} rowSpacing={2} sx={{ py: 1 }}>
                <GridDX item xs={6}>
                  <SelectListDX
                    items={l1Categories}
                    name="l1CategoryId"
                    label={t("Category")}
                    onChange={handleInputChange}
                    value={filterFields.l1CategoryId}
                    InputLabelProps={{
                      shrink: filterFields.l1CategoryId !== null, // Set to true if there's a value just to handle label position
                    }}
                  />
                </GridDX>

                <GridDX item xs={6}>
                  <AutoCompleteMultiple
                    sx={{ width: "100%" }}
                    label={t("Sub Category")}
                    name={"l2CategoryIds"}
                    placeholder={"Sub Category"}
                    list={l2Categories}
                    value={filterFields.l2CategoryIds}
                    onChange={(value: any) =>
                      handleMultiSelectInputChange(value, "l2CategoryIds")
                    }
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <AutoCompleteMultiple
                    sx={{ width: "100%" }}
                    label={t("Group")}
                    name={"l3CategoryIds"}
                    placeholder={"Group"}
                    list={l3Categories}
                    value={filterFields.l3CategoryIds}
                    onChange={(value: any) =>
                      handleMultiSelectInputChange(value, "l3CategoryIds")
                    }
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <AutoCompleteMultiple
                    sx={{ width: "100%" }}
                    label={t("Sub Group")}
                    name={"l4CategoryIds"}
                    placeholder={"Sub Group"}
                    list={l4Categories}
                    value={filterFields.l4CategoryIds}
                    onChange={(value: any) =>
                      handleMultiSelectInputChange(value, "l4CategoryIds")
                    }
                  />
                </GridDX>
              </GridDX>
              <Typography
                fontSize={20}
                sx={{ paddingTop: 2, display: "flex", alignItems: "center" }}
                gutterBottom
              >
                <FormatListBulletedOutlinedIcon fontSize="small" />{" "}
                {t("Manufacturer Filters")}
              </Typography>
              <Divider />
              <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
                <GridDX item xs={12}>
                  <TextFieldDX
                    label={t("Manufacturer Serial Code")}
                    name="manufacturerSerialCode"
                    placeholder={t("Manufacturer Serial Code")}
                    value={filterFields.manufacturerSerialCode}
                    onChange={handleInputChange}
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <TextFieldDX
                    label={t("Manufacturer Brand")}
                    name="manufacturerBrand"
                    placeholder={t("Manufacturer Brand")}
                    value={filterFields.manufacturerBrand}
                    onChange={handleInputChange}
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <TextFieldDX
                    label={t("Manufacturer Model")}
                    name="manufacturerModel"
                    placeholder={t("Manufacturer Model")}
                    value={filterFields.manufacturerModel}
                    onChange={handleInputChange}
                  />
                </GridDX>
              </GridDX>
              <Typography
                fontSize={20}
                sx={{ paddingTop: 2, display: "flex", alignItems: "center" }}
                gutterBottom
              >
                <FormatListBulletedOutlinedIcon fontSize="small" />{" "}
                {t("Other Filters")}
              </Typography>
              <Divider />
              <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
                <GridDX item xs={12}>
                  <AutoCompleteMultiple
                    sx={{ width: "100%" }}
                    label={t("Custodian")}
                    name={"custodianIds"}
                    placeholder={"Custodian"}
                    list={custodiansList}
                    value={filterFields.custodianIds}
                    onChange={(value: any) =>
                      handleMultiSelectInputChange(value, "custodianIds")
                    }
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <AutoCompleteMultiple
                    sx={{ width: "100%" }}
                    label={t("Asset Condition")}
                    name={"assetConditionTypeIds"}
                    placeholder={"Asset Type"}
                    list={assetConditionTypesList}
                    value={filterFields.assetConditionTypeIds}
                    onChange={(value: any) =>
                      handleMultiSelectInputChange(
                        value,
                        "assetConditionTypeIds"
                      )
                    }
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <AutoCompleteMultiple
                    sx={{ width: "100%" }}
                    label={t("Asset Status")}
                    name={"assetStatusTypeIds"}
                    placeholder={"Asset Status"}
                    list={assetStatusTypesList}
                    value={filterFields.assetStatusTypeIds}
                    onChange={(value: any) =>
                      handleMultiSelectInputChange(value, "assetStatusTypeIds")
                    }
                  />
                </GridDX>
              </GridDX>
            </GridDX>
          </GridDX>
        </CardContent>
      </CardDX>
      <div ref={scrollRef} />
      {isReportGenerated && (
        <GridDX container sx={{ my: 4 }}>
          <CardDX sx={{ p: 2, pb: 6 }}>
            <GridDX item xs={12}>
              <ListPageDX
                gridTitle={t("Generated Report")}
                listTitle={t("Assets")}
                name={t("asset")}
                rows={rows}
                columns={columns}
                getRowId={(row: any) => row.assetId}
                isLoading={isLoading}
                searchAndFilter={false}
                exportToPDF={false}
              />
            </GridDX>
          </CardDX>
        </GridDX>
      )}
    </GridDX>
  );
};

export default Reports;
