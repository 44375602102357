import useSecureAPI from "../hooks/usesecureapi";

const useEmployeeService = () => {
  const secureAPI = useSecureAPI();

  const getEmployees = async () => {
    const response = await secureAPI.get("Employee");
    return response.data;
  };


  const getEmployeeById = async (id: number) => {
    const response = await secureAPI.get(`Employee/${id}`);
    return response.data;
  };
  
  const addEmployee = async (data: any) => {
    const response = await secureAPI.post("Employee", data);
    return response.data;
  };

  const updateEmployee = async (id: number, data: any) => {
    const response = await secureAPI.put(`Employee`, data);
    return response.data;
  };

  const deleteEmployee = async (id: number) => {
    const response = await secureAPI.delete(`Employee/${id}`);
    return response.data;
  };

  return {
    getEmployees,
    getEmployeeById,
    addEmployee,
    updateEmployee,
    deleteEmployee,
  };
};

export default useEmployeeService;