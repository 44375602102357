import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import TextFieldDX from "../controls/textfielddx";
import GridDX from "../layout/griddx";
import ButtonDX from "../controls/buttondx";
import DatePickerDX from "../controls/datepickerdx";
import SelectListDX from "../controls/selectlistdx";
import AutoCompleteMultiple from "../controls/autocompletemultiple";

const FilterModal = (props: any) => {
  const { title, handleClose, allRows, setRows, filterFields, open } = props;
  const [isChanged, setIsChanged] = useState(false);
  const onClose = () => {
    handleClose();
  };
  // make an state of array of fields with the length of filterFields
  const [fields, setFields] = useState(new Array(filterFields.length).fill(""));

  // comparison type
  // const [comparisonTypes, setComparisonTypes] = useState(
  //   new Array(filterFields.length).fill("=")
  // );

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setFields((prev: any) => {
      const newFields = [...prev];
      newFields[filterFields.findIndex((f: any) => f.name === name)] = value;
      return newFields;
    });
  };

  const handleDateChange = (value: any, name: any) => {
    setIsChanged(true);
    setFields((prev: any) => {
      const newFields = [...prev];
      newFields[filterFields.findIndex((f: any) => f.name === name)] = new Date(
        value
      );
      return newFields;
    });
  };

  const applyFilters = () => {
    // filter rows on filter field and comparison type and setRows according to filter criteria
    const newRows = allRows.filter((row: any) => {
      return fields.every((field: any, index: number) => {
        if (field) {
          // if (comparisonTypes[index] === "=") {
          //   return row[filterFields[index].name] === field;
          // } else if (comparisonTypes[index] === ">") {
          //   return row[filterFields[index].name] > field;
          // } else if (comparisonTypes[index] === "<") {
          //   return row[filterFields[index].name] < field;
          // }
        }
        return true;
      });
    });

    console.log("newRows", newRows);

    // onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={"xl"}
      disableEscapeKeyDown
      sx={{ backgroundColor: "transparent" }}
    >
      <DialogTitle>{title}</DialogTitle>

      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ p: 2, px: 3 }}>
        {filterFields?.map((filter: any, index: number) => (
          <>
            {filter.type == "date" ? (
              <GridDX item xs={6}>
                <DatePickerDX
                  label={filter.label}
                  name={filter.name}
                  sx={{ flex: 1 }}
                  value={fields[index] || new Date()}
                  onChange={(value: any) =>
                    handleDateChange(value, "entryDate")
                  }
                  format="DD-MM-YYYY"
                />
              </GridDX>
            ) : filter.type == "dropdown" ? (
              <GridDX item xs={6}>
                <SelectListDX
                  items={filter.list}
                  label={filter.label}
                  name={filter.name}
                  onChange={handleInputChange}
                  value={fields[index]}
                />
              </GridDX>
            ) : filter.type == "multiselect" ? (
              <GridDX item xs={6}>
                <AutoCompleteMultiple
                  sx={{ width: "100%" }}
                  label={filter.label}
                  name={filter.name}
                  placeholder={filter.label}
                  list={filter.list}
                  // value={fields[index]}
                  onChange={handleDateChange}
                />
              </GridDX>
            ) : (
              <GridDX item xs={6}>
                <TextFieldDX
                  label={filter.label}
                  name={filter.name}
                  value={fields[index]}
                  onChange={handleInputChange}
                  // onChange={(e: any) => {
                  //   setIsChanged(true);
                  //   const value = e.target.value;
                  //   const lowercasedValue = value.toLowerCase();
                  //   const newRows = props.allRows.filter((row: any) =>
                  //     row[filter.name]?.toLowerCase().includes(lowercasedValue)
                  //   );
                  //   setNewRows(newRows);
                  // }}
                />
              </GridDX>
            )}

            {/* <GridDX item xs={6}>
              <SelectListDX
                items={[
                  { text: "=", value: "=" },
                  { text: ">", value: ">" },
                  { text: "<", value: "<" },
                ]}
                value={comparisonTypes[index]}
                onChange={(e: any) => {
                  const { value } = e.target;
                  setComparisonTypes((prev: any) => {
                    const newComparisonTypes = [...prev];
                    newComparisonTypes[index] = value;
                    return newComparisonTypes;
                  });
                }}
                label={"Comparison type"}
                name={"comparisonType"}
              />
            </GridDX> */}
          </>
        ))}
      </GridDX>

      <DialogActions sx={{ p: 2, px: 3 }}>
        <ButtonDX
          variant="contained"
          color="primary"
          onClick={() => {
            applyFilters();
          }}
        >
          Generate Report
        </ButtonDX>
        <ButtonDX
          variant="contained"
          color="primary"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </ButtonDX>
      </DialogActions>
    </Dialog>
  );
};

export default FilterModal;
