import "./App.css";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuthContext } from "./context/authcontext";
import { NotificationContextProvider } from "./context/notificationcontext";

import useRefreshToken from "./shared/hooks/userefreshtoken";

import LoadingOverlay from "./components/loadingoverlay";
import ProtectedRoute from "./components/route/protectedroute";

import AccountTemplate from "./templates/accounttemplate";
import MainTemplate from "./templates/maintemplate";
import ListPageTemplate from "./templates/listpagetemplate";

import LoginPage from "./pages/account/login";
import Dashboard from "./pages/dashboard";
import GetEmail from "./pages/account/forgetPassword/getEmail";
import ResetPassword from "./pages/account/forgetPassword/resetPassword";
import Reports from "./pages/reports";
import AssetsPage from "./pages/asset/assets";
import LocationsPage from "./pages/settings/locations";
import CategoriesPage from "./pages/settings/categories";
import DepartmentsPage from "./pages/settings/departments";
import TreeViewTemplate from "./templates/treeviewtemplate";
import Users from "./pages/settings/users";
import PendingAssets from "./pages/asset/pendingassets";

function App() {
  return (
    <NotificationContextProvider>
      <AuthProvider>
        <Router>
          <ApplicationRoutes />
        </Router>
      </AuthProvider>
    </NotificationContextProvider>
  );
}

const ApplicationRoutes = () => {
  const { authInitialized, isLoggedIn, fullToken, userData, signOut } =
    useAuthContext();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const refresh = useRefreshToken();
  const location = useLocation();

  const refreshToken = () => {
    if (userData.rememberMe) {
      refresh()
        .then(() => {
          console.log("refreshed");
        })
        .catch(() => {
          signOut();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      signOut();
    }
  };

  useEffect(() => {
    setLoading(false);
    if (authInitialized && !isLoggedIn) {
      setLoading(false);
    } else if (authInitialized && isLoggedIn && fullToken) {
      const currentDateTime = new Date().getTime();
      const expiryDateTime = new Date(fullToken.expiryDate).getTime();
      if (expiryDateTime < currentDateTime) {
        refreshToken();
      } else if (
        location.pathname === "/" ||
        location.pathname === "/forget-password"
      ) {
        setLoading(false);
        //to redirect to dashboard if user is logged in and tries to access login page
        navigate("/dashboard");
      } else {
        setLoading(false);
      }
    }
  }, [location.pathname, isLoggedIn, authInitialized]);

  if (loading) return <LoadingOverlay />;
  else
    return (
      <Routes>
        <Route element={<AccountTemplate />}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/forget-password" element={<GetEmail />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
        </Route>
        <Route
          element={
            <ProtectedRoute>
              <MainTemplate />
            </ProtectedRoute>
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/reports" element={<Reports />} />

          <Route element={<TreeViewTemplate />}>
            <Route path="/locations" element={<LocationsPage />} />
            <Route path="/categories" element={<CategoriesPage />} />
          </Route>
          <Route element={<ListPageTemplate />}>
            <Route path="/departments" element={<DepartmentsPage />} />
            <Route path="/assets" element={<AssetsPage />} />
            <Route path="/pending-assets" element={<PendingAssets />} />
            <Route path="/users" element={<Users />} />
          </Route>

          {/* to redirect user to dashboard if he tries to access invalid url */}
          {/* <Route path="*" element={<Navigate to="/dashboard" replace />} /> */}
        </Route>
      </Routes>
    );
};

export default App;
