import useSecureAPI from "../hooks/usesecureapi";

const useCustodyService = () => {
  const secureAPI = useSecureAPI();

  const getCustodyTypes = async () => {
    const response = await secureAPI.get(`CustodyType`);
    return response.data;
  };

  const getCustodyTypeById = async (id: number) => {
    const response = await secureAPI.get(`CustodyType/${id}`);
    return response.data;
  }
  return {
    getCustodyTypes,
    getCustodyTypeById
  };
};

export default useCustodyService;
