import React, { useEffect } from "react";
import GridDX from "../../layout/griddx";
import TextFieldDX from "../../controls/textfielddx";
import { useTranslation } from "react-i18next";
import DatePickerDX from "../../controls/datepickerdx";
import SelectListDX from "../../controls/selectlistdx";
import CheckBoxDX from "../../controls/checkboxdx";
import UploadBoxDX from "../../controls/uploadboxdx";

const LocationTab = (props: any) => {
  const {
    assetData,
    setAssetData,
    errors,
    locations,
    setIsChanged,
    departmentsDropDownData,
    handleInputChange,
  } = props;
  const { t } = useTranslation();

  const [l1Locations, setL1Locations] = React.useState<any>([]);
  const [l2Locations, setL2Locations] = React.useState<any>([]);
  const [l3Locations, setL3Locations] = React.useState<any>([]);
  const [l4Locations, setL4Locations] = React.useState<any>([]);
  const [l5Locations, setL5Locations] = React.useState<any>([]);
  const [l6Locations, setL6Locations] = React.useState<any>([]);
  const [l7Locations, setL7Locations] = React.useState<any>([]);
  useEffect(() => {
    let l1 = locations.filter((l: any) => l.parentLocationId === null);
    l1 = l1.map((l: any) => ({
      value: l.locationId,
      text: l.name,
    }));
    setL1Locations(l1);
  }, []);
  useEffect(() => {
    let l2 = locations.filter(
      (l: any) =>
        l.parentLocationId === assetData.l1LocationId && l.levelNumber === 2
    );
    l2 = l2.map((l: any) => ({
      value: l.locationId,
      text: l.name,
    }));
    setL2Locations(l2);
  }, [assetData.l1LocationId]);

  useEffect(() => {
    let l3 = locations.filter(
      (l: any) =>
        l.parentLocationId === assetData.l2LocationId && l.levelNumber === 3
    );
    l3 = l3.map((l: any) => ({
      value: l.locationId,
      text: l.name,
    }));
    setL3Locations(l3);
  }, [assetData.l2LocationId]);

  useEffect(() => {
    let l4 = locations.filter(
      (l: any) =>
        l.parentLocationId === assetData.l3LocationId && l.levelNumber === 4
    );
    l4 = l4.map((l: any) => ({
      value: l.locationId,
      text: l.name,
    }));
    setL4Locations(l4);
  }, [assetData.l3LocationId]);

  useEffect(() => {
    let l5 = locations.filter(
      (l: any) =>
        l.parentLocationId === assetData.l4LocationId && l.levelNumber === 5
    );
    l5 = l5.map((l: any) => ({
      value: l.locationId,
      text: l.name,
    }));
    setL5Locations(l5);
  }, [assetData.l4LocationId]);

  const handleLocationChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    if (name === "l1LocationId") {
      setAssetData({
        ...assetData,
        [name]: value,
        l2LocationId: null,
        l3LocationId: null,
        l4LocationId: null,
        l5LocationId: null,
        l6LocationId: null,
        l7LocationId: null,
      });
    } else if (name === "l2LocationId") {
      setAssetData({
        ...assetData,
        [name]: value,
        l3LocationId: null,
        l4LocationId: null,
        l5LocationId: null,
        l6LocationId: null,
        l7LocationId: null,
      });
    } else if (name === "l3LocationId") {
      setAssetData({
        ...assetData,
        [name]: value,
        l4LocationId: null,
        l5LocationId: null,
        l6LocationId: null,
        l7LocationId: null,
      });
    } else if (name === "l4LocationId") {
      setAssetData({
        ...assetData,
        [name]: value,
        l5LocationId: null,
        l6LocationId: null,
        l7LocationId: null,
      });
    } else if (name === "l5LocationId") {
      setAssetData({
        ...assetData,
        [name]: value,
        l6LocationId: null,
        l7LocationId: null,
      });
    } else if (name === "l6LocationId") {
      setAssetData({
        ...assetData,
        [name]: value,
        l7LocationId: null,
      });
    } else {
      setAssetData({
        ...assetData,
        [name]: value,
      });
    }
  };

  return (
    <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
      <GridDX item xs={12}>
        <SelectListDX
          items={departmentsDropDownData}
          id="departmentCode"
          name="departmentCode"
          label={t("Department")}
          onChange={handleInputChange}
          value={assetData.departmentCode}
          error={errors["departmentCode"]}
          InputLabelProps={{
            shrink: assetData.departmentCode !== null, // Set to true if there's a value just to handle label position
          }}
        />
      </GridDX>
      <GridDX item xs={12}>
        <SelectListDX
          items={l1Locations}
          name="l1LocationId"
          label={t("Country")}
          onChange={handleLocationChange}
          value={assetData.l1LocationId}
          error={errors["l1LocationId"]}
          InputLabelProps={{
            shrink: assetData.l1LocationId !== null, // Set to true if there's a value just to handle label position
          }}
        />
      </GridDX>
      <GridDX item xs={12}>
        <SelectListDX
          items={l2Locations}
          name="l2LocationId"
          label={t("City")}
          onChange={handleLocationChange}
          value={assetData.l2LocationId}
          error={errors["l2LocationId"]}
          InputLabelProps={{
            shrink: assetData.l2LocationId !== null, // Set to true if there's a value just to handle label position
          }}
        />
      </GridDX>
      <GridDX item xs={12}>
        <SelectListDX
          items={l3Locations}
          name="l3LocationId"
          label={t("Branch")}
          onChange={handleLocationChange}
          value={assetData.l3LocationId}
          error={errors["l3LocationId"]}
          InputLabelProps={{
            shrink: assetData.l3LocationId !== null, // Set to true if there's a value just to handle label position
          }}
        />
      </GridDX>

      {/* location */}
      <GridDX item xs={12}>
        <SelectListDX
          items={l4Locations}
          name="l4LocationId"
          label={t("Location")}
          onChange={handleLocationChange}
          value={assetData.l4LocationId}
          error={errors["l4LocationId"]}
          InputLabelProps={{
            shrink: assetData.l4LocationId !== null, // Set to true if there's a value just to handle label position
          }}
        />
      </GridDX>
      <GridDX item xs={12}>
        <SelectListDX
          items={l5Locations}
          name="l5LocationId"
          label={t("Sub Location")}
          onChange={handleLocationChange}
          value={assetData.l5LocationId}
          error={errors["l5LocationId"]}
          InputLabelProps={{
            shrink: assetData.l5LocationId !== null, // Set to true if there's a value just to handle label position
          }}
        />
      </GridDX>

      {/* <GridDX item xs={12}>
        <SelectListDX
          items={l6Locations}
          name="l6LocationId"
          label={t("Floor")}
          onChange={handleLocationChange}
          value={assetData.l6LocationId}
          error={errors["l6LocationId"]}
          InputLabelProps={{
            shrink: assetData.l6LocationId !== null, // Set to true if there's a value just to handle label position
          }}
        />
      </GridDX> */}
    </GridDX>
  );
};

export default LocationTab;
