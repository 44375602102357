import { CardContent } from "@mui/material";
import CardDX from "./carddx";
import BoxDX from "./boxdx";
import GridDX from "./griddx";
import applicationLogo from "../../assets/application_login_logo.png";

const LoginBox = (props: any) => {
  return (
    <CardDX
      sx={{ width: { xs: 350, sm: 486 }, margin: "auto", borderColor: "none" }}
    >
      <CardContent sx={{ px: { xs: "24px", sm: "60px" } }}>
        <GridDX item xs={12} justifyContent="center" mb={4}>
          <BoxDX>
            <img src={applicationLogo} height={45} alt="" />
          </BoxDX>
        </GridDX>
        {props.children}
      </CardContent>
    </CardDX>
  );
};

export default LoginBox;
