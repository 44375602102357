import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../context/notificationcontext";
import ListPageDX from "../../components/business/listpagedx";
import AssetTabsModal from "../../components/addeditmodals/assetmodaltabs/assettabsmodal";
import useAssetService from "../../shared/services/assetservice";
import TypeTranslator from "../../shared/typetranslator";
import FilterModal from "../../components/addeditmodals/filtermodal";
import { DateFormatter } from "../../shared/globals";

const AssetsPage = () => {
  const { getAssets, deleteAsset, getAssetStatusTypes } = useAssetService();

  const { t, i18n } = useTranslation();
  const { setInfo, setError } = useNotificationContext();
  const [assetId, setAssetId] = useState<any>(null);
  const [rows, setRows] = useState<any>([]);
  const [assets, setAssets] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { getassetStatusValue } = TypeTranslator();

  const columns: GridColDef[] = [
    {
      field: "assetCode",
      headerName: `${t("Asset Code")}`,
      flex: 1,
    },
    {
      field: "description",
      headerName: `${t("Description")}`,
      flex: 1,
    },
    {
      field: "entryDate",
      headerName: `${t("Entry Date")}`,
      flex: 1,
      valueGetter(params) {
        return DateFormatter(params.row.entryDate, 0, 0, 0);
      },
    },
    {
      field: "status",
      headerName: `${t("Status")}`,
      flex: 1,
    },
  ];

  const getData = () => {
    setIsLoading(true); // commented out due to error from server side

    getAssets() // api to fetch data types from the server
      .then((response) => {
        response.forEach((asset: any) => {
          asset.status = getassetStatusValue(asset.assetStatusTypeId);
        });

        setAssets(response);
        setRows(response);

        const assetCodes = response.map((res: any) => ({
          text: res.assetCode,
          value: res.assetCode,
        }));
        setAssetCodesList(assetCodes);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (assetId: number) => {
    setIsLoading(true);
    deleteAsset(assetId)
      .then(() => {
        setInfo(t("Asset deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (asset: any) => {
    setAssetId(asset.assetId);
    setOpen(!open);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setAssetId(null);
    setOpen(!open);

    if (refreshPage) getData();
  };

  useEffect(() => {
    getData();
  }, [i18n.language]);
  // get dropdown data
  useEffect(() => {
    const p1 = getAssetStatusTypes();
    Promise.all([p1])
      .then(([assetTypes]) => {
        setAssetStatusesList(assetTypes);
      })
      .catch((err) => setError(err));
  }, []);

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(assets);
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const newRows = assets.filter(
      ({ assetCode, description, entryDate, status }: any) =>
        assetCode?.toLowerCase().includes(lowercasedValue) ||
        description?.toLowerCase().includes(lowercasedValue) ||
        entryDate?.toLowerCase().includes(lowercasedValue) ||
        status?.toLowerCase().includes(lowercasedValue)
    );
    setRows(newRows);
  };
  const [openFilter, setOpenFilter] = useState(false);
  const [assetStatusesList, setAssetStatusesList] = useState([]);
  const [assetCodesList, setAssetCodesList] = useState([]);
  const allFilters = () => {
    setOpenFilter(true);
  };
  const customButtons = [
    {
      label: t("Generate Report"),
      onClick: allFilters,
    },
  ];
  const filterFields = [
    {
      label: t("Asset Code"),
      name: "assetCode",
      type: "multiselect",
      list: assetCodesList,
    },
    {
      label: t("Description"),
      name: "description",
      type: "text",
    },
    {
      label: t("Entry Date"),
      name: "entryDate",
      type: "date",
    },
    {
      label: t("Location"),
      name: "l1LocationId",
      type: "multiselect",
      list: assetCodesList,
    },
    {
      label: t("Category"),
      name: "l1CategoryId",
      type: "multiselect",
      list: assetCodesList,
    },
    {
      label: t("User"),
      name: "addedByUserId",
      type: "multiselect",
      list: assetCodesList,
    },

    {
      label: t("Status"),
      name: "status",
      type: "dropdown",
      list: assetStatusesList.map((res: any) => ({
        text: res.description,
        value: res.assetStatusTypeId,
      })),
    },
  ];
  return (
    <>
      {open && (
        <AssetTabsModal open={open} handleClose={onClose} assetId={assetId} />
      )}
      {openFilter && (
        <FilterModal
          title="Filters"
          open={openFilter}
          handleClose={() => setOpenFilter(false)}
          filterFields={filterFields}
          allRows={assets}
          setRows={setRows}
        />
      )}
      <ListPageDX
        listTitle={t("Assets")}
        name={t("asset")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.assetId}
        isLoading={isLoading}
        toCreate={toCreate}
        onEdit={onEdit}
        onDelete={onDelete}
        setGridFilterCriteria={setGridFilterCriteria}
      />
    </>
  );
};

export default AssetsPage;
