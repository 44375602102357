import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import CorrespondenceIcon from "@mui/icons-material/ArticleOutlined";
import ReportsIcon from "@mui/icons-material/PrintOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ViewListIcon from "@mui/icons-material/ViewList";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import CalendarIcon from "@mui/icons-material/CalendarMonthOutlined";
import MeetingIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ArchiveIcon from "@mui/icons-material/ArchiveOutlined";
import InvitationIcon from "@mui/icons-material/EmailOutlined";

import {
  CategoryOutlined,
  ContactPageOutlined,
  DomainOutlined,
  GroupOutlined,
  LabelImportantOutlined,
  PrivacyTipOutlined,
  Person,
  MeetingRoomOutlined,
  DeviceHubOutlined,
  DevicesFoldOutlined,
  DevicesOtherOutlined,
} from "@mui/icons-material";
import i18n from "../../multilingual/i18n";

const MenuItems = (props: any) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const [open, setOpen] = useState(props ? props.open : false);
  const [activeIndex, setActiveIndex] = useState(0);

  const menuItems = [
    {
      id: 1,
      text: t("Dashboard"),
      icon: <InsertChartOutlinedIcon />,
      link: "/dashboard",
    },
    {
      id: 2,
      text: t("Reports"),
      icon: <ReportsIcon />,
      link: "/reports",
    },

    {
      id: 3,
      text: t("Fixed Assets Cards"),
      icon: <ViewListIcon />,
      link: "/assets",
    },
    {
      id: 4,
      text: t("Pending Assets"),
      icon: <ViewListIcon />,
      link: "/pending-assets",
    },

    {
      id: 5,
      text: t("Settings"),
      icon: <SettingsApplicationsOutlinedIcon />,
      links: [
        {
          id: 501,
          text: t("Locations"),
          icon: <CategoryOutlined />,
          link: "/locations",
        },
        {
          id: 502,
          text: t("Categories"),
          icon: <DevicesOtherOutlined />,
          link: "/categories",
        },
        {
          id: 503,
          text: t("Departments"),
          icon: <GroupOutlined />,
          link: "/departments",
        },

        {
          id: 504,
          text: t("Users"),
          icon: <Person />,
          link: "/users",
        },
      ],
    },
  ];

  useEffect(() => {
    const pathName = location.pathname.substring(1);
    let currentItem: any = null;

    menuItems.forEach((i) => {
      const link = i.link?.split("").splice(1).join("");
      if (i.link && link === pathName) {
        currentItem = i;
      } else if (i.links && currentItem == null) {
        currentItem = i.links.find((si) => si.link?.includes(pathName));
      }
    });

    if (currentItem) setActiveIndex(currentItem.id);
  }, [location.pathname]);

  useEffect(() => {
    if (props) setOpen(props?.open);
  }, [props?.open]);

  return (
    <List
      sx={{
        px: 1,
        flex: "auto",
        overflowY: "auto",
        overflowX: open ? "auto" : "hidden",
      }}
      dir={i18n.dir()}
    >
      {menuItems.map((item: any, index) => {
        if (item.link)
          return (
            <MenuItem
              key={`menuItem_${index}`}
              item={item}
              open={open}
              activeIndex={activeIndex}
              onClick={props.onClick}
            />
          );
        else
          return (
            <SubMenus
              key={`subMenu_${index}`}
              item={item}
              menuIsOpen={open}
              activeIndex={activeIndex}
              onClick={props.onClick}
            />
          );
      })}
    </List>
  );
};

const MenuItem = ({ item, open, activeIndex, onClick }: any) => {
  const activeBgColor = "#f0a03b";
  const activeTextColor = "#3a3939";
  const defaultBgColor = "transparent";
  const defaultTextColor = "#f0a03b";

  const { i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <ListItem
      key={item.text}
      disablePadding
      sx={{
        display: "block",
        borderRadius: 1,
        my: 2,
        background: item.id === activeIndex ? activeBgColor : defaultBgColor,
        color: item.id === activeIndex ? activeTextColor : defaultTextColor,
      }}
    >
      <ListItemButton
        sx={{
          height: 46,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
          color: "inherit",
        }}
        onClick={() => {
          navigate(item.link);
          if (onClick) {
            onClick();
          }
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: i18n.dir() === "ltr" ? (open ? 3 : "auto") : "auto",
            ml: i18n.dir() === "rtl" ? (open ? 3 : "auto") : "auto",
            justifyContent: "center",
            color: "inherit",
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.text}
          sx={{ opacity: open ? 1 : 0, textAlign: "initial" }}
        />
        {/* {item.id !== activeIndex && open && (
          <ListItemIcon
            sx={{
              minWidth: 0,
              opacity: open ? 1 : 0,
              justifyContent: "flex-end",
              color: "inherit",
            }}
          >
            {i18n.dir() === "ltr" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </ListItemIcon>
        )} */}
      </ListItemButton>
    </ListItem>
  );
};

const SubMenus = ({ item, menuIsOpen, activeIndex }: any) => {
  const activeBgColor = "#f0a03b";
  const activeTextColor = "#3a3939";
  const defaultBgColor = "transparent";
  const defaultTextColor = "#f0a03b";
  const [openSubMenu, setOpenSubMenu] = useState(false);

  useEffect(() => {
    if (!menuIsOpen) setOpenSubMenu(false);
  }, [menuIsOpen]);

  const handleClick = () => {
    setOpenSubMenu(!openSubMenu);
  };

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          background: item.id === activeIndex ? activeBgColor : defaultBgColor,
          color: item.id === activeIndex ? activeTextColor : defaultTextColor,

          padding: "8px 20px",
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: i18n.dir() === "ltr" ? (menuIsOpen ? 3 : "auto") : "auto",
            ml: i18n.dir() === "rtl" ? (menuIsOpen ? 3 : "auto") : "auto",
            justifyContent: "center",
            color: "inherit",
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.text}
          sx={{
            opacity: menuIsOpen ? 1 : 0,
            textAlign: i18n.dir() === "rtl" ? "right" : "left",
          }}
        />
        {menuIsOpen && (openSubMenu ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      <Collapse in={openSubMenu} timeout="auto" unmountOnExit sx={{ pl: 2 }}>
        <List component="div" disablePadding>
          {item.links.map((item: any, index: number) =>
            item.link ? (
              <MenuItem
                key={`subMenu_menuIten_${index}`}
                item={item}
                index={index}
                open={openSubMenu}
                activeIndex={activeIndex}
              />
            ) : (
              <SubMenus
                key={`subMenu_${index}`}
                item={item}
                menuIsOpen={openSubMenu}
                activeIndex={activeIndex}
              />
            )
          )}
        </List>
      </Collapse>
    </>
  );
};

export default MenuItems;
