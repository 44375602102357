import { useTranslation } from "react-i18next";

const TypeTranslator = () => {
  const { i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const assetStatus: any = [
    {},
    {
      assetStatusTypeId: 1,
      description: "Activated",
    },
    {
      assetStatusTypeId: 2,
      description: "Sold",
    },
    {
      assetStatusTypeId: 3,
      description: "Halt",
    },
    {
      assetStatusTypeId: 4,
      description: "Maintained",
    },
    {
      assetStatusTypeId: 5,
      description: "Purchased",
    },
    {
      assetStatusTypeId: 6,
      description: "Requested",
    },
    {
      assetStatusTypeId: 7,
      description: "Salvaged",
    },
    {
      assetStatusTypeId: 8,
      description: "Under Inspection",
    },
    {
      assetStatusTypeId: 9,
      description: "Waiting Parts",
    },
  ];

  const assetTypes = [
    {},
    {
      assetTypeId: 1,
      description: "Financial",
    },
    {
      assetTypeId: 2,
      description: "Technical",
    },
    {
      assetTypeId: 3,
      description: "Both",
    },
  ];

  assetStatus.forEach((status: any) => {
    status.en_value = status.description;
    status.ar_value = status.description;
  });

  const getassetStatusValue = (value: number) => {
    const status = assetStatus[value];

    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  const getAssetTypeValue = (value: number) => {
    const type = assetTypes[value];
    if (type) {
      return type.description;
    } else return value;
  };
  return {
    getassetStatusValue,
    getAssetTypeValue,
  };
};

export default TypeTranslator;
